import React, { useState } from "react";
import ContactHero from "../components/contactComponents/ContactHero";
import ContactDescription from "../components/contactComponents/ContactDescription";
import { useEffect } from "react";
import ContactStep from "../components/contactComponents/ContactStep";
import FAQs from "../components/contactComponents/FAQs";

export default function Capiflex() {
   const [data, setData] = useState(null);

   useEffect(() => {
     const url =
       "https://capicom.capival.com/api/website/faq?category=capiflex";
     const username = "qxDtRrIcuN";
     const password = "rzsl7x16LlH7Waoj1N2Y";

     fetch(url, {
       method: "GET",
       headers: {
         Authorization: "Basic " + btoa(username + ":" + password),
       },
     })
       .then((response) => response.json())
       .then((data) => setData(data?.faqs))
       .catch((error) => console.error("Error:", error));
   }, []);
   console.log("data====", data);
  const pathname = window.location.pathname;
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [pathname]);
  return (
    <div className="min-h-screen">
      <ContactHero />
      <ContactDescription />
      <ContactStep />
      <div className="max-w-[1520px] mx-auto md:p-[60px] p-4">
        <h1 className="my-[20px]">Frequently Asked Questions</h1>
        <FAQs data={data}/>
      </div>
    </div>
  );
}
