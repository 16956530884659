import React from "react";
import { Fade } from "react-reveal";

const CasaVideo = () => {
  return (
    <Fade bottom>
      <div className="min-h-[693px] md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
        <div className="max-w-[1520px] mx-auto  ">
          <h1 className="md:text-[44px] text-[30px] text-[#09112C] font-bold text-center">
            Casa in Video
          </h1>
          <div className="flex p-10 gap-[80px] flex-col lg:flex-row justify-center items-center">
            <div className="flex-1">
              <video controls width="100%" height="600">
                <source type="video/mp4" src="/videos/community.mp4" />
              </video>
            </div>
            <div className="flex-1">
              <video controls width="100%" height="600">
                <source type="video/mp4" src="/videos/community.mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default CasaVideo;
