import Layout from "./components/Layout";
import { Route, Routes, useParams } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import ErrorPage from "./pages/ErrorPage";
import Contact from "./pages/Contact";
import Capiflex from "./pages/Capiflex";
import Capigrow from "./pages/Capigrow";
import Capisave from "./pages/Capisave";
import CasaPage from "./pages/CasaPage";
import FaqPage from "./pages/FaqPage";
import Policy from "./pages/Policy";
const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product/capiflex" element={<Capiflex />} />
        <Route path="/product/capigrow" element={<Capigrow />} />
        <Route path="/product/capisave" element={<Capisave />} />
        <Route path="/product/casa" element={<CasaPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Layout>
  );
}

export default App;
