import React from "react";
import { Link } from "react-router-dom";
const Arrow = () => {
  return (
    <svg
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6688 0.468825C16.9688 0.168871 17.3757 0.000366211 17.7999 0.000366211C18.2242 0.000366211 18.6311 0.168871 18.9312 0.468825L25.3312 6.86882C25.6311 7.16887 25.7996 7.57576 25.7996 8.00002C25.7996 8.42429 25.6311 8.83118 25.3312 9.13122L18.9312 15.5312C18.6294 15.8227 18.2252 15.9839 17.8057 15.9803C17.3862 15.9767 16.9849 15.8084 16.6882 15.5117C16.3916 15.2151 16.2233 14.8138 16.2197 14.3943C16.216 13.9748 16.3773 13.5706 16.6688 13.2688L20.3376 9.60002H1.79995C1.3756 9.60002 0.968638 9.43145 0.66858 9.1314C0.368522 8.83134 0.199951 8.42437 0.199951 8.00002C0.199951 7.57568 0.368522 7.16871 0.66858 6.86865C0.968638 6.5686 1.3756 6.40002 1.79995 6.40002H20.3376L16.6688 2.73122C16.3688 2.43118 16.2003 2.02429 16.2003 1.60002C16.2003 1.17576 16.3688 0.768869 16.6688 0.468825Z"
        fill="#F16026"
      />
    </svg>
  );
};
const LinkedSvg = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5001 4.72498H6.3001C5.88238 4.72498 5.48177 4.89091 5.1864 5.18628C4.89103 5.48165 4.7251 5.88226 4.7251 6.29998V31.5C4.7251 31.9177 4.89103 32.3183 5.1864 32.6137C5.48177 32.909 5.88238 33.075 6.3001 33.075H31.5001C31.9178 33.075 32.3184 32.909 32.6138 32.6137C32.9092 32.3183 33.0751 31.9177 33.0751 31.5V6.29998C33.0751 5.88226 32.9092 5.48165 32.6138 5.18628C32.3184 4.89091 31.9178 4.72498 31.5001 4.72498ZM13.134 28.8808H8.92562V15.3515H13.134V28.8808ZM11.0298 13.504C10.3832 13.504 9.76306 13.2472 9.30583 12.7899C8.84859 12.3327 8.59172 11.7126 8.59172 11.0659C8.59172 10.4193 8.84859 9.79916 9.30583 9.34193C9.76306 8.8847 10.3832 8.62783 11.0298 8.62783C11.6764 8.62783 12.2966 8.8847 12.7538 9.34193C13.2111 9.79916 13.4679 10.4193 13.4679 11.0659C13.4679 11.7126 13.2111 12.3327 12.7538 12.7899C12.2966 13.2472 11.6764 13.504 11.0298 13.504ZM28.8824 28.8808H24.6788V22.302C24.6788 20.7333 24.6504 18.7157 22.4927 18.7157C20.3034 18.7157 19.9711 20.4262 19.9711 22.1917V28.8823H15.7706V15.3531H19.8026V17.2021H19.8608C20.42 16.139 21.7934 15.0176 23.8361 15.0176C28.0949 15.0176 28.8809 17.818 28.8809 21.4625V28.8808H28.8824Z"
        fill="#E21F27"
      />
    </svg>
  );
};
const IT = () => {
  return (
    <div className="md:flex mt-[100px] shadow-sm gap-[20px]">
      <div className="flex-[33.3%]">
        <img
          src="/images/headIt.JPG"
          alt="No Yet"
          className="max-w-[365.52px] h-[500px] object-cover mx-auto rounded-md"
        />
      </div>
      <div className="flex-[66.6%] lg:flex">
        <div className="flex-[40%] flex items-center justify-center">
          <div className="mt-5">
            <h1 className="text-[#F16026] font-bold text-[2.25rem]">
              Oluwamuyiwa <br /> Fawole
            </h1>
            <p className="my-[1.3rem] font-normal text-[#1E1E1E]">
              Head of Information Technology
            </p>
            <Link to="">
              <LinkedSvg />
            </Link>
          </div>{" "}
        </div>
        <div className="flex-[60%] flex justify-center items-center">
          <div className="px-5">
            <p className="text-[start] line-clamp-[10] text-[14px] leading-[23.7px] my-[2rem]">
              Olumuyiwa Fawole currently serves as Capival Investment Ltd.&#39;s
              head of information technology. He is one of the founding members
              of the company&#39;s workforce and has been described as a
              &quot;technology mastermind&quot;. He holds a Bachelor of Science
              degree in Accounting from University of Lagos. He is a full stack
              software developer and a recent graduate of Microverse, a school
              for software development in the United States, where he obtained
              several certifications in both frontend and backend software
              development. Olumuyiwa spent over 7 years working for United Bank
              for Africa plc in the branch operations department and IT support
              prior to his current position.
            </p>
            <Link
              to="/"
              className="flex justify-start items-center gap-[23px] text-[14px] "
            >
              Read more <Arrow />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IT;
