import React, { useState } from 'react'
import GrowHero from '../components/flexgrow/GrowHero';
import Protfolio from '../components/flexgrow/Protfolio';
import Freedom from '../components/flexgrow/Freedom';
import Investor from '../components/flexgrow/Investor';
import FAQs from '../components/contactComponents/FAQs';
import { useEffect } from 'react';

const Capigrow = () => {
   const [data, setData] = useState(null);

   useEffect(() => {
     const url =
       "https://capicom.capival.com/api/website/faq?category=capigrow";
     const username = "qxDtRrIcuN";
     const password = "rzsl7x16LlH7Waoj1N2Y";

     fetch(url, {
       method: "GET",
       headers: {
         Authorization: "Basic " + btoa(username + ":" + password),
       },
     })
       .then((response) => response.json())
       .then((data) => setData(data?.faqs))
       .catch((error) => console.error("Error:", error));
   }, []);
   console.log("data====", data);
   const pathname = window.location.pathname;
   const onTop = () => {
     window.scrollTo(0, 0);
   };
   useEffect(() => {
     onTop();
   }, [pathname]);
  return (
    <div className="py-[2rem] w-full">
      <GrowHero />
      <Protfolio />
      <Freedom />
      <Investor />
      <div className="max-w-[1520px] mx-auto md:p-[60px] p-4">
        <h1 className="my-[20px]">Frequently Asked Questions</h1>
        <FAQs data={data} />
      </div>
    </div>
  );
}

export default Capigrow
