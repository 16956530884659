import React, { useState } from "react";
import CasaHero from "../components/casa/CasaHero";
import ThinkCasa from "../components/casa/ThinkCasa";
import { useEffect } from "react";
import CasaVideo from "../components/casa/CasaVideo";
import FAQs from "../components/contactComponents/FAQs";

const CasaPage = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
      const url =
        "https://capicom.capival.com/api/website/faq?category=capisave";
      const username = "qxDtRrIcuN";
      const password = "rzsl7x16LlH7Waoj1N2Y";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic " + btoa(username + ":" + password),
        },
      })
        .then((response) => response.json())
        .then((data) => setData(data?.faqs))
        .catch((error) => console.error("Error:", error));
    }, []);
  const pathname = window.location.pathname;
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [pathname]);
  return (
    <>
      <CasaHero />
      <ThinkCasa />
      <CasaVideo />
      <div className="max-w-[1520px] mx-auto md:p-[60px] p-4">
        <h1 className="my-[20px]">Frequently Asked Questions</h1>
        <FAQs data={data}/>
      </div>
    </>
  );
};

export default CasaPage;
