import React, { useEffect } from "react";
import HeroSecion from "../components/homeComponents/HeroSecion";
import ProductSection from "../components/homeComponents/ProductSection";
import WhyCustomer from "../components/homeComponents/WhyCustomer";
import SlideCard from "../components/homeComponents/productSlide/SlideCard";
import Level from "../components/homeComponents/Level";
import JoinCapival from "../components/homeComponents/JoinCapival";
import Testimonial from "../components/homeComponents/Testimonial";
import KnowledgeCenter from "../components/homeComponents/KnowledgeCenter";

export default function Home() {
  const pathname = window.location.pathname;
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [pathname]);
  return (
    <div className="min-h-screen">
      <HeroSecion />
      <ProductSection />
      <WhyCustomer />
      <SlideCard />
      <Level />
      <JoinCapival />
      <Testimonial />
      {/* <KnowledgeCenter /> */}
    </div>
  );
}
