import React from "react";
import WhyCustomersCard from "./WhyCustomersCard";
import Fade from "react-reveal/Fade";

export default function WhyCustomer() {
  return (
    <div className="bg-[#09112C] min-h-[594px] flex justify-center items-center md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
      <Fade bottom>
        <div className="text-white max-w-[1512px] mx-auto">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-center md:text-[2.75rem] md:font-extrabold text-[1.8rem] font-bold leading-8">
              Why Our Customers Love Us
            </h1>
            <p className="text-center text-[#FFF] font-normal max-w-[681px] md:text-[1.25rem] leading-[29.17px] my-4">
              We’re here to save you the stress of having to worry about your
              finances. With Capival, life is easy peasy.
            </p>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[60px] my-[3.6rem]">
            <WhyCustomersCard
              icon="/images/simpleProcess.png"
              title="Simple and Transparent process"
              detail="Your peace of mind is guaranteed when you transact with us. No need to fret."
            />
            <WhyCustomersCard
              icon="/images/thumb.png"
              title="Quick Transactions "
              detail="Access our products and services anywhere, anytime."
            />
            <WhyCustomersCard
              icon="/images/support.png"
              title="Hands-On Support"
              detail="Stress-free transactions are our thing. Access our range of products and services without hassle."
            />
          </div>
        </div>
      </Fade>
    </div>
  );
}

