import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Fade from "react-reveal/Fade";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./knowledge.css";

// import required modules
import { Navigation } from "swiper";
import Ceo from "../mgt/Ceo";
import Coo from "../mgt/Coo";
import Bd from "../mgt/Bd";
import Bdm from "../mgt/Bdm";
import { useEffect } from "react";
import IT from "../mgt/IT";
import MgtStaff from "../mgt/MgtStaff";

const Card = ({ name, onClick, title, bg }) => {
  return (
    <div
      className="max-w-[348px] cursor-pointer h-[78px] relative text-white flex justify-center items-center"
      style={{
        backgroundImage: `${bg}`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      onClick={onClick}
    >
      <div className="absolute w-full top-0 left-0 bottom-0 right-0 h-full transition-all hover:bg-[#551C24] bg-[#787777C4]"></div>
      <div className="z-50">
        <h1 className="text-[18px] font-bold">{name}</h1>
        <h1 className="text-[14px] font-normal">{title}</h1>
      </div>
    </div>
  );
};

const Leadership = () => {
  const [activeComponent, setActiveComponent] = useState(<Ceo />);
  const [active, setActive] = useState("ceo");

  const handleClick = (position) => {
    setActive(position);
    console.log("Button clicked!", position);
  };

  useEffect(() => {
    if (active === "ceo") {
      setActiveComponent(<Ceo />);
    } else if (active === "coo") {
      setActiveComponent(<Coo />);
    } else if (active === "bd") {
      setActiveComponent(<Bd />);
    } else if (active === "it") {
      setActiveComponent(<IT />);
    } else if (active === "mgt") {
      setActiveComponent(<MgtStaff />);
    } else {
      setActiveComponent(<Bdm />);
    }
  }, [active]);
  return (
    <Fade bottom>
      <section className="md:py-[4.6rem] max-w-[1520px] mx-auto py-[2rem] lg:px-[64px] px-[1rem] w-full">
        <h1 className="text-[#551C24] font-bold text-[2.5rem] mb-[2rem]">
          Leadership Team
        </h1>
        <div className="">
          <Swiper
            slidesPerView={1.3}
            spaceBetween={60}
            navigation={true}
            modules={[Navigation]}
            className="blog"
            breakpoints={{
              630: {
                slidesPerView: 1.5,
              },
              920: {
                slidesPerView: 2.5,
              },
              1154: {
                slidesPerView: 3.5,
              },
            }}
          >
            <SwiperSlide>
              <Card
                name="Adetokunbo Ajetunmobi"
                title="CEO/Founder"
                bg="url('/images/ceo.png')"
                onClick={() => {
                  handleClick("ceo");
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                name="Oscar Ede"
                title="COO/Co-Founder"
                bg="url('/images/oscar.png')"
                onClick={() => {
                  handleClick("coo");
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                name="Morris Omotayo"
                title="Head, Busines Development"
                bg="url('/images/moris.png')"
                onClick={() => {
                  handleClick("bd");
                }}
              />
            </SwiperSlide>
            {/* <SwiperSlide>
              <Card
                name="Oyinkansola Evboren"
                title="Management Staff"
                onClick={() => {
                  handleClick("bdm");
                }}
              />
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <Card
                name="Leesibari Nalelo"
                title="Financial Analyst."
                onClick={() => {
                  handleClick("mgt");
                }}
              />
            </SwiperSlide> */}
            <SwiperSlide>
              <Card
                name="Oluwamuyiwa  Fawole"
                title="Head, Information Technology"
                onClick={() => {
                  handleClick("it");
                }}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <>{activeComponent}</>
      </section>
    </Fade>
  );
};

export default Leadership;
