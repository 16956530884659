import React from "react";
import Fade from "react-reveal/Fade";

const Download = () => {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499701 26.8753C0.554904 12.0509 12.7338 -0.0381326 27.5758 -6.29195e-05C42.4521 0.0380068 54.5549 12.2298 54.4997 27.1208C54.4445 41.9813 42.2085 54.0856 27.3303 53.998C12.4997 53.9105 0.444498 41.7224 0.499701 26.8753ZM41.725 28.5313C40.5067 27.0961 39.3874 25.7769 38.2148 24.3969C35.5308 26.7192 32.9134 28.9824 30.0961 31.4189V13.1873H24.6748V31.3675C21.8766 28.9501 19.2611 26.6887 16.6038 24.3912C15.395 25.8207 14.2605 27.1608 13.0993 28.5332C17.9553 32.66 22.7027 36.6934 27.5035 40.7726C32.2491 36.6877 36.9451 32.6466 41.725 28.5313Z"
        fill="#FFB41E"
      />
    </svg>
  );
};
const Apply = () => {
  return (
    <svg
      width="40"
      height="54"
      viewBox="0 0 40 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.8019 0H3.197C1.7081 0 0.5 1.21945 0.5 2.72349V51.2765C0.5 52.7805 1.7081 54 3.197 54H36.8019C38.2919 54 39.5 52.7805 39.5 51.2765V2.72349C39.5 1.21945 38.2919 0 36.8019 0ZM13.2796 36.6145C12.8086 37.0911 12.1564 37.3859 11.4363 37.3859H8.75403C7.31269 37.3859 6.14535 36.2065 6.14535 34.7527C6.14535 34.0259 6.43747 33.3676 6.90961 32.891C7.38062 32.4155 8.03279 32.1207 8.75403 32.1207H11.4363C12.8765 32.1207 14.0439 33.299 14.0439 34.7527C14.0439 35.4796 13.7517 36.1379 13.2796 36.6145ZM19.2352 25.1628C18.7642 25.6383 18.112 25.9331 17.3919 25.9331H8.75403C7.31269 25.9331 6.14535 24.7548 6.14535 23.3011C6.14535 22.5742 6.43747 21.9159 6.90961 21.4393C7.38062 20.9627 8.03279 20.6679 8.75403 20.6679H17.3919C18.8321 20.6679 19.9994 21.8473 19.9994 23.3011C19.9994 24.0279 19.7073 24.6862 19.2352 25.1628ZM24.2125 13.8803C23.7404 14.3569 23.0882 14.6517 22.3681 14.6517H8.75403C7.31269 14.6517 6.14535 13.4734 6.14535 12.0197C6.14535 11.2928 6.43747 10.6345 6.90961 10.1591C7.38062 9.68249 8.03279 9.38763 8.75403 9.38763H22.3681C23.8083 9.38763 24.9756 10.5659 24.9756 12.0197C24.9756 12.7466 24.6835 13.4049 24.2125 13.8803Z"
        fill="#FFB41E"
      />
    </svg>
  );
};
const Verify = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_775_2802)">
        <path
          d="M1.43833e-05 28.9716C0.015753 12.8333 12.9498 -0.0472391 29.0913 -2.31939e-05C45.173 0.0471927 58.0032 12.9119 58 28.9937C58 45.0817 44.981 58.0598 28.9119 58C12.8868 57.937 -0.0157242 44.9841 1.43833e-05 28.9716ZM23.6898 32.4027C22.6479 31.2789 21.798 30.2811 20.8663 29.3683C18.5086 27.0641 15.3231 27.0169 13.1827 29.2109C11.0832 31.3608 11.1681 34.4833 13.4282 36.7875C15.5183 38.9185 17.6367 41.0211 19.7583 43.1207C22.3017 45.6389 25.2196 45.6672 27.785 43.1647C30.0325 40.9708 32.2359 38.7265 34.4582 36.5042C37.9018 33.0605 41.3769 29.6484 44.7796 26.1607C47.2789 23.5985 46.9798 19.9314 44.2067 17.9735C42.0253 16.4343 39.3497 16.7301 37.2376 18.8297C32.7836 23.2585 28.3579 27.7189 23.6898 32.3964V32.4027Z"
          fill="#FFB41E"
        />
      </g>
      <defs>
        <clipPath id="clip0_775_2802">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
const StepCard = ({ icon, title, text }) => {
  return (
    <div className="flex flex-col justify-center items-center max-w-[248px]">
      <>{icon}</>
      <h4 className="text-[#FFB41E] font-extrabold md:my-[30px] my-5 md:text-[1.2rem] text-[1rem] text-center">
        {title}
      </h4>
      <p className="text-[1rem] font-normal text-center">{text}</p>
    </div>
  );
};

function ContactStep() {
  return (
    <Fade bottom>
      <div className="bg-[#09112C] text-white md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem] ">
        <div className="max-w-[1520px] mx-auto">
          <h1 className="text-center text-[2.5rem] font-bold">
            Your loan is 3 steps away
          </h1>
          <div className="max-w-[993px] md:gap-[100px] gap-[10px] mx-auto flex justify-center items-center md:my-[96px] my-[30px]">
            <StepCard
              icon={<Download />}
              title="Download"
              text="Download the Capival app on Appstore or Google Play"
            />
            <StepCard
              icon={<Apply />}
              title="Apply"
              text="Apply for a loan and submit documents."
            />
            <StepCard
              icon={<Verify />}
              title="Verify"
              text="Verify documents & get credited!"
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default ContactStep;
