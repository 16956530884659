import React from "react";
import Fade from "react-reveal/Fade";
const Position = () => {
  return (
    <div
      style={{
        backgroundImage: "url('/images/positionBg.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: 'center'
      }}
      className="text-white flex justify-center mb-[100px] items-center md:px-[115px] px-[1rem] md:py-[10rem] py-[7.6rem] w-full"
    >
      <Fade bottom>
        <div className="max-w-[890px] mx-auto flex justify-center flex-col items-center">
          <h1 className="md:text-[2.25rem] text-[1.8rem] font-bold text-center">
            Life is good at Capival
          </h1>
          <p className="my-[22px] md:text-[1.25rem] text-[1rem] font-normal text-center">
            We’re a team of young , vibrant professionals dedicated to making
            the world a better place, one financial solution at a time. Our
            values are interwoven into everything we do and we’re always ready
            to take on any challenge and get things done! 
          </p>
          <p className="font-bold md:text-[1.25rem] text-[1rem] text-center">
            Want to be part of the future of finance and tech? Join the Capival
            side of life.
          </p>
          <button className="mt-[3.8rem] border text-[1rem] py-2 px-3 rounded-xl hidden">
            View Open Positions
          </button>
        </div>
      </Fade>
    </div>
  );
};

export default Position;
