import Flex from "./Flex";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import Fade from "react-reveal/Fade";

import { Pagination, Autoplay } from "swiper";
import Grow from "./Grow";
import Save from "./Save";
import Casa from "./Casa";

const SlideCard = () => {
  return (
    <div className="bg-[#F9F9F9] min-h-[771px] md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
        <Fade right>
          <section className="max-w-[1512px] mx-auto">
            <>
              <Swiper
                pagination={true}
                modules={[Pagination, Autoplay]}
                autoplay={{
                  delay: 10000,
                  disableOnInteraction: true,
                }}
                loop={true}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Flex />
                </SwiperSlide>
                <SwiperSlide>
                  <Grow />
                </SwiperSlide>
                <SwiperSlide>
                  <Save />
                </SwiperSlide>
                <SwiperSlide>
                  <Casa />
                </SwiperSlide>
              </Swiper>
            </>
          </section>
        </Fade>
    </div>
  );
};

export default SlideCard;
