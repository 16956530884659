import React from 'react'
import { Link } from 'react-router-dom';

export default function Button({title, url}) {
  return (
    <Link to={url} className="bgGradient px-[30px] py-[10px] text-white my-[10px] ">
      {title}
    </Link>
  );
}
