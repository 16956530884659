import React, { useEffect } from 'react'
import Hero from '../components/aboutComponents/Hero'
import BuildSection from '../components/aboutComponents/BuildSection'
import Vision from '../components/aboutComponents/Vision'
import WhatDrivesUs from '../components/aboutComponents/WhatDrivesUs'
import Leadership from '../components/aboutComponents/Leadership'
import Position from '../components/aboutComponents/Position'

export default function About() {
  const pathname = window.location.pathname;
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => { 
    onTop();
  }, [pathname]);
  return (
    <div className='min-h-screen'>
      <Hero />
      <BuildSection />
      <Vision />
      <WhatDrivesUs />
      <Leadership />
      <Position />
    </div>
  )
}
