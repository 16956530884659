import React from "react";
import { Fade } from "react-reveal";
const Customize = () => {
  return (
    <svg
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.93847 13.5829V7.4927C2.56663 7.23261 3.19675 7.04278 3.7591 6.72392C5.27868 5.86325 6.22744 4.52834 6.59561 2.76715C6.63927 2.55975 6.72594 2.50166 6.92338 2.50166C11.3101 2.50706 15.6975 2.50706 20.0842 2.50166C20.2856 2.50166 20.3585 2.57124 20.4094 2.77121C21.0597 5.33293 22.6171 6.91578 25.1434 7.41974C25.5005 7.49068 25.8791 7.49406 26.2433 7.46501C26.6428 7.43258 26.9673 7.12587 26.9719 6.72324C26.9862 5.53223 27.054 4.32702 26.9119 3.15222C26.7216 1.58087 25.4086 0.506047 23.8773 0.504696C16.9603 0.499292 10.0433 0.49794 3.12572 0.504696C1.40218 0.506723 0.0239964 1.89568 0.0161762 3.68456C-0.00402451 8.22164 -0.00402451 12.758 0.0161762 17.2951C0.0239964 19.0664 1.4517 20.4946 3.16091 20.4959C10.0564 20.5006 16.9519 20.5013 23.8473 20.4959C25.6484 20.4946 26.9849 19.0955 26.9973 17.2228C27.0031 16.3561 27.0012 15.4893 26.9973 14.6226C26.994 13.8295 26.6278 13.4707 25.8635 13.5018C23.2283 13.6099 21.0245 15.4866 20.4244 18.1531C20.367 18.4071 20.2908 18.5003 20.0282 18.5003C15.674 18.4915 11.3192 18.4929 6.96508 18.4983C6.7507 18.4983 6.64839 18.4557 6.58975 18.2091C6.01502 15.7933 4.55668 14.278 2.22844 13.6653C2.1346 13.641 2.04207 13.6126 1.93847 13.5829Z"
        fill="#E21F27"
      />
      <path
        d="M13.4973 15.4987C16.1924 15.5008 18.3187 13.3032 18.3232 10.5131C18.3278 7.70344 16.2002 5.49705 13.4901 5.5011C10.7983 5.50516 8.67856 7.70952 8.67987 10.503C8.68117 13.2951 10.8042 15.4967 13.4967 15.4987H13.4973Z"
        fill="#E21F27"
      />
      <path
        d="M25.0833 10.4976C25.0833 11.0942 25.469 11.5008 26.0372 11.5022C26.6061 11.5035 26.9945 11.0982 26.9951 10.5017C26.9951 9.90652 26.6081 9.49848 26.0405 9.4978C25.4716 9.49713 25.0833 9.90247 25.0826 10.4976H25.0833Z"
        fill="#E21F27"
      />
    </svg>
  );
};
const Hidden = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4386 16.5269C21.5157 17.5736 22.5605 18.5877 23.6036 19.6029C24.049 20.0363 24.0496 20.416 23.6073 20.849C23.3143 21.1357 23.0218 21.4219 22.7261 21.706C22.3157 22.1002 21.9123 22.0971 21.504 21.6998C16.1184 16.4592 10.7322 11.2185 5.34655 5.97737C4.12184 4.78551 2.89713 3.59417 1.67295 2.40232C1.21959 1.96112 1.22118 1.58347 1.67666 1.14175C1.97713 0.850374 2.27707 0.55848 2.57967 0.269169C2.95499 -0.0893692 3.37066 -0.0909191 3.74386 0.270719C4.68297 1.18101 5.6242 2.08873 6.55162 3.01039C6.73636 3.19379 6.87173 3.22634 7.13239 3.13283C11.8396 1.43881 16.2129 1.99573 20.1487 5.08567C22.0492 6.57769 23.2474 8.56257 23.9837 10.8151C24.015 10.9111 23.9965 11.0357 23.9646 11.1364C23.327 13.1492 22.2181 14.8809 20.66 16.3409C20.5968 16.3998 20.5272 16.4525 20.4386 16.5269ZM10.2555 6.50639C12.346 8.54087 14.4515 10.5893 16.5452 12.6269C17.2215 11.0687 16.9216 8.82759 15.2127 7.35676C13.627 5.99184 11.4399 5.94121 10.2555 6.50639Z"
        fill="#E21F27"
      />
      <path
        d="M2.23572 6.66553C6.5538 10.8678 10.8565 15.055 15.1873 19.2696C14.7966 19.3471 14.4154 19.4427 14.0284 19.497C10.28 20.0234 6.88612 19.217 3.92759 16.8803C2.10459 15.4404 0.893684 13.5785 0.101099 11.4392C-0.0374578 11.0651 -0.0310874 10.7438 0.104815 10.379C0.596929 9.05957 1.26742 7.83982 2.16458 6.7394C2.18635 6.71306 2.21236 6.68981 2.23572 6.66553Z"
        fill="#E21F27"
      />
    </svg>
  );
};

const Interest = () => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.93847 13.083V6.99277C2.56663 6.73267 3.19675 6.54284 3.7591 6.22398C5.27868 5.36331 6.22744 4.0284 6.59561 2.26721C6.63927 2.05982 6.72594 2.00172 6.92338 2.00172C11.3101 2.00712 15.6975 2.00712 20.0842 2.00172C20.2856 2.00172 20.3585 2.0713 20.4094 2.27127C21.0597 4.833 22.6171 6.41584 25.1434 6.91981C25.5005 6.99074 25.8791 6.99412 26.2433 6.96507C26.6428 6.93264 26.9673 6.62594 26.9719 6.2233C26.9862 5.03229 27.054 3.82708 26.9119 2.65228C26.7216 1.08093 25.4086 0.00610826 23.8773 0.00475713C16.9603 -0.000647358 10.0433 -0.00199848 3.12572 0.00475713C1.40218 0.00678382 0.0239964 1.39574 0.0161762 3.18463C-0.00402451 7.7217 -0.00402451 12.2581 0.0161762 16.7952C0.0239964 18.5665 1.4517 19.9946 3.16091 19.996C10.0564 20.0007 16.9519 20.0014 23.8473 19.996C25.6484 19.9946 26.9849 18.5955 26.9973 16.7229C27.0031 15.8561 27.0012 14.9894 26.9973 14.1226C26.994 13.3295 26.6278 12.9708 25.8635 13.0019C23.2283 13.11 21.0245 14.9867 20.4244 17.6531C20.367 17.9071 20.2908 18.0004 20.0282 18.0004C15.674 17.9916 11.3192 17.9929 6.96508 17.9983C6.7507 17.9983 6.64839 17.9558 6.58975 17.7092C6.01502 15.2934 4.55668 13.7781 2.22844 13.1654C2.1346 13.1411 2.04207 13.1127 1.93847 13.083Z"
        fill="#E21F27"
      />
      <path
        d="M13.4973 14.9988C16.1924 15.0008 18.3187 12.8032 18.3232 10.0132C18.3278 7.2035 16.2002 4.99711 13.4901 5.00117C10.7983 5.00522 8.67856 7.20958 8.67987 10.003C8.68117 12.7951 10.8042 14.9968 13.4967 14.9988H13.4973Z"
        fill="#E21F27"
      />
      <path
        d="M25.0833 9.99763C25.0833 10.5942 25.469 11.0008 26.0372 11.0022C26.6061 11.0035 26.9945 10.5982 26.9951 10.0017C26.9951 9.40652 26.6081 8.99848 26.0405 8.9978C25.4716 8.99713 25.0833 9.40247 25.0826 9.99763H25.0833Z"
        fill="#E21F27"
      />
    </svg>
  );
};

const Business = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5086 5.47867C20.79 5.47867 22.018 5.47451 23.2466 5.48214C23.5079 5.48353 23.7765 5.4988 24.0292 5.56128C25.1476 5.83549 25.9447 6.86708 25.9936 8.07569C25.9975 8.16801 25.9995 8.26034 26.0002 8.35267C26.0002 13.2753 26.0021 18.1986 25.9988 23.1212C25.9982 24.529 25.242 25.5953 24.0206 25.9091C23.7679 25.9743 23.4993 25.9959 23.238 25.9959C16.4178 26.0007 9.59764 26 2.77745 25.9986C1.10276 25.9986 0.00411084 24.8539 0.00345099 23.1003C0.000151752 18.1888 0.000151752 13.278 0.00345099 8.36656C0.00477069 6.61855 1.08824 5.48145 2.74248 5.47867C3.8629 5.47728 4.98267 5.47867 6.10309 5.47867C6.22054 5.47867 6.33865 5.47867 6.49372 5.47867C6.49372 4.62133 6.4825 3.80356 6.499 2.98648C6.5056 2.64285 6.52935 2.2895 6.61513 1.95975C6.90876 0.825426 7.90447 0.0152902 9.02358 0.00973655C11.6702 -0.00206491 14.3175 -0.00484173 16.9642 0.00973655C18.3974 0.018067 19.4802 1.22112 19.5059 2.79835C19.5185 3.54948 19.5086 4.30061 19.5086 5.05174C19.5086 5.17739 19.5086 5.30304 19.5086 5.47798V5.47867ZM16.8764 5.46132V2.75323H9.13113V5.46132H16.8764Z"
        fill="#E21F27"
      />
    </svg>
  );
};

const Flexible = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0332 17.3137C18.0332 17.4528 18.0332 17.5621 18.0332 17.6709C18.0332 18.9605 18.0382 20.2495 18.0316 21.5391C18.0261 22.6897 17.3523 23.5571 16.3005 23.7819C16.1765 23.8083 16.0465 23.8189 15.9198 23.8195C13.7986 23.8217 11.6775 23.8307 9.55639 23.8172C8.52655 23.8111 7.69917 23.0973 7.49068 22.0718C7.45227 21.8817 7.45008 21.6821 7.44953 21.4864C7.44623 20.2064 7.44788 18.9263 7.44788 17.6462C7.44788 17.5459 7.44788 17.4455 7.44788 17.3109C7.03693 17.3109 6.65506 17.325 6.27484 17.3087C3.689 17.1982 1.76703 15.9692 0.636789 13.6031C-1.2347 9.68716 1.21398 5.02947 5.43484 4.41438C5.86719 4.35159 6.09104 4.18898 6.31489 3.80603C7.84347 1.1831 10.1462 -0.0728597 13.1117 0.00339481C14.8724 0.0488111 16.4163 0.740149 17.7177 1.96246C17.8873 2.1217 18.0486 2.17945 18.2752 2.17216C21.8931 2.05386 24.9595 4.44915 25.8028 8.04152C26.7838 12.2209 23.9873 16.5181 19.8328 17.1966C19.3571 17.2745 18.8694 17.2779 18.3871 17.3126C18.2801 17.3205 18.1726 17.3137 18.0332 17.3137ZM18.0332 15.1534C20.9636 15.4836 23.6202 13.2167 23.877 10.2041C24.1717 6.74632 21.2396 3.87387 17.8917 4.34766C17.3863 4.41943 16.9968 4.27757 16.6473 3.88453C14.0187 0.925178 9.20579 1.84528 7.78311 5.5711C7.50604 6.29664 7.22348 6.48784 6.4559 6.46933C4.00503 6.41046 1.96455 8.53886 2.10007 10.9992C2.23559 13.4567 4.23272 15.5958 7.44843 15.1394C7.44843 15.0328 7.44843 14.9229 7.44843 14.8125C7.44953 13.4668 7.43526 12.1211 7.45666 10.7755C7.47477 9.62492 8.39213 8.68632 9.51085 8.67791C11.6594 8.66221 13.8085 8.66333 15.9571 8.67791C17.005 8.68464 17.8944 9.51167 17.9976 10.5764C18.0508 11.1231 18.0294 11.6771 18.0321 12.2282C18.0365 13.1994 18.0332 14.1699 18.0332 15.1539V15.1534ZM15.9225 21.6737V10.8198H13.2686V21.6737H15.9225ZM9.55035 21.6681H11.1437V10.8254H9.55035V21.6681Z"
        fill="#E21F27"
      />
      <path
        d="M22.3181 23.8234C21.7101 23.8234 21.1653 23.8234 20.621 23.8234C20.4746 23.8234 20.3286 23.8256 20.1821 23.8217C19.5715 23.8043 19.1342 23.3642 19.1309 22.7648C19.1276 22.1581 19.5758 21.6972 20.1958 21.6888C20.8806 21.6798 21.5653 21.6866 22.3213 21.6866C22.2023 21.5217 22.1134 21.4006 22.0262 21.2784C21.6443 20.7435 21.7151 20.102 22.1957 19.7359C22.6747 19.3709 23.3117 19.4763 23.6946 19.9938C24.2076 20.6868 24.719 21.3804 25.221 22.0813C25.5546 22.5461 25.5486 22.9868 25.2139 23.4488C24.7141 24.1396 24.2104 24.8281 23.7018 25.5122C23.3089 26.0404 22.6862 26.1497 22.2061 25.7869C21.7123 25.4135 21.6421 24.776 22.0349 24.2243C22.1178 24.1077 22.2001 23.9899 22.3186 23.8223L22.3181 23.8234Z"
        fill="#E21F27"
      />
    </svg>
  );
};
const ThinkCasa = () => {
  return (
    <Fade bottom>
      <div className="bg-[#09112C] text-white md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
        <div className="lg:flex max-w-[1520px] justify-center items-center mx-auto flex-col-reverse lg:flex-row gap-[80px]">
          <div className="flex-1">
            <div className="w-[301px] h-[326px] mx-auto">
              <img
                src="/images/casa-left.png"
                alt="casa hero"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="mt-10 lg:mt-0">
              <h1 className="md:text-[44px] text-[30px] font-bold">
                Think Rent, Think Casa
              </h1>
              <p className="md:text-[24px] text-[16px] font-normal md:my-[60px] my-[20px]">
                Whether you’re looking to refinance your current apartment, move
                into a new one or rent an office space, we’re your ultimate
                plug.
              </p>
              <ul className="flex flex-col gap-[20px]">
                <li className="flexx ">
                  <Customize />
                  <span className="flexx-span">Up to N6 Million</span>
                </li>
                <li className="flexx">
                  <Hidden />
                  <span className="flexx-span">No hidden fees</span>
                </li>
                <li className="flexx ">
                  <Interest />
                  <span className="flexx-span">Competitive interest rates</span>
                </li>
                <li className="flexx ">
                  <Business />
                  <span className="flexx-span">
                    Accessible to business owners and salary earners
                  </span>
                </li>
                <li className="flexx">
                  <Flexible />
                  <span className="flexx-span">Flexible repayments</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default ThinkCasa;
