import React, { useState, useEffect } from "react";
import {
  BsTelephoneFill,
  BsFacebook,
  BsLinkedin,
  BsInstagram,
  BsTwitter,
  BsCheckLg,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const Contact = () => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [pathname]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log(formData);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const url = "https://capicom.capival.com/api/website/contact-us";
      const username = "qxDtRrIcuN";
      const password = "rzsl7x16LlH7Waoj1N2Y";
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Basic " + btoa(username + ":" + password),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await res.json();
      console.log(data);
      setLoading(false);
      setError(null);
      setSuccess(true);
      setError(data.message);
    } catch (error) {
      setLoading(false);
      // setError(data?.message);
    }
  };
  const handleClose = (e) => {
    e.preventDefault();
    setSuccess(false);
    navigate("/");
  };
  return (
    <div className="relative w-full">
      {success && (
        <div
          id="toast-default"
          class="flex fixed left-0 right-0 top-[50%] z-50 m-auto items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow"
          role="alert"
        >
          <BsCheckLg color="green" />
          <div class="ml-3 text-sm font-normal">{error}</div>
          <button
            type="button"
            onClick={handleClose}
            class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            data-dismiss-target="#toast-default"
            aria-label="Close"
          >
            <span class="sr-only">Close</span>
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
        </div>
      )}
      <div
        className="bg-[#551C24] lg:py-[4.6rem] py-[2rem]  lg:px-[64px] px-[1rem] lg:min-h-[616px] h-[450px] text-[#FFFFFF]"
        style={{
          backgroundImage: "url('/images/contactImg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-[1520px] mx-auto">
          <h1 className="md:text-[61px] text-[40px] font-bold">Get in touch</h1>
          <p className="md:text-[20px] text-[16px] font-normal  md:max-w-[587px]">
            Maximize your money’s potential with smart investment opportunities.
            With us, you can relax and watch your money do the work.
          </p>
        </div>
      </div>
      <div className="absolute w-[80%] max-w-[1520px]  left-0 right-0 mx-auto lg:top-[405px]  overflow-hidden top-[250px] md:flex text-white z-20 my-contact">
        <div className="flex-[66.6%] lg:p-[67px] md:p-5 p-10">
          <form onSubmit={handleSubmit}>
            <h2 className="text-[#181818] text-[30px] font-bold">
              Send a Message
            </h2>
            <div className="grid md:grid-cols-2 gap-[39px]">
              <input
                type="text"
                placeholder="First Name"
                id="first_name"
                onChange={handleChange}
                className="outline-none border-b border-[#0000004D] py-3 text-[#33333352] text-[18px] font-medium"
              />
              <input
                type="text"
                placeholder="Last Name"
                id="last_name"
                onChange={handleChange}
                className="outline-none border-b border-[#0000004D] py-3 text-[#33333352] text-[18px] font-medium"
              />
              <input
                type="tel"
                placeholder="+234"
                id="phone"
                onChange={handleChange}
                className="outline-none border-b border-[#0000004D] py-3 text-[#33333352] text-[18px] font-medium"
              />
              <input
                type="email"
                id="email"
                placeholder="Email Address"
                onChange={handleChange}
                className="outline-none border-b border-[#0000004D] py-3 text-[#33333352] text-[18px] font-medium"
              />
            </div>
            <div className="my-10 w-full">
              <textarea
                name=""
                id="message"
                cols="30"
                rows="10"
                className="border-b-2 outline-none p-2 w-full bg-slate-100 text-black "
                placeholder="Inquiry or Complaint"
                onChange={handleChange}
              />
            </div>
            <button className="bgGradient px-[30px] py-[10px] text-white my-[10px] ">
              {loading ? "Sending" : "Submit"}
            </button>
          </form>
          <p>{error}</p>
        </div>
        <div className="flex-[33.3%] bg-[#09112C] lg:py-[67px] lg:px-[30px] p-10 md:p-5 relative overflow-hidden">
          <img
            src="/images/clogo.png"
            alt="logo"
            className="absolute bottom-0 right-0 object-cover w-[100px] h-[70px] md:w-[300px] md:h-[200px]"
          />
          <>
            <h3 className="text-[20px] font-bold">Contact Information</h3>
            <div className="text-[#FFE000] font-bold flex items-center gap-[20px] my-10">
              <a href="tel:+2349082274825">
                <BsTelephoneFill fontSize={24} />
              </a>
              <a href="https://www.facebook.com/CapivalInvestment?mibextid=LQQJ4ds">
                <BsFacebook fontSize={24} />
              </a>
              <a href="https://www.linkedin.com/company/capival-investment-limited/">
                <BsLinkedin fontSize={24} />
              </a>
              <a href="https://instagram.com/capivalinvestment?igshid=MzRlODBiNWFlZA">
                <BsInstagram fontSize={24} />
              </a>
              <a href="https://x.com/capivalinvest?s=11&t=hferjIXcTimi7uaEWteKrQ">
                <BsTwitter fontSize={24} />
              </a>
            </div>
            <p className="text-[#FFE000] text-[16px] font-bold">
              info@capival.com
            </p>
            <p className="my-10 font-bold text-[18px]">
              16 Chief Albert Iyorah Street, off Admiralty-way Lekki Phase1,
              Lagos Nigeria
            </p>
          </>
        </div>
      </div>
      <div className="md:min-h-[616px] min-h-[1450px] bg-[#FFFFFF]"></div>
    </div>
  );
};

export default Contact;
