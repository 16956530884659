import React, { useEffect } from "react";

export default function Policy() {
  const pathname = window.location.pathname;

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [pathname]);

  const sections = [
    { id: "section1", title: "Introduction" },
    { id: "section2", title: "To whom this Privacy Policy applies" },
    { id: "section3", title: "Other Data Privacy Documents" },
    { id: "section4", title: "Other Websites" },
    { id: "section5", title: "Consent" },
    { id: "section6", title: "What information do we collect" },
    { id: "section7", title: "How We use Information About You" },
    { id: "section8", title: "To whom we disclose your information" },
    { id: "section9", title: "International transfers" },
    { id: "section10", title: "Marketing communications" },
    { id: "section11", title: "How long do we keep your personal data" },
  ];

  return (
    <div className="min-h-screen flex">
      <div className="md:w-1/4 w-full h-screen sticky top-0 p-4 bg-gray-100 hidden md:inline-block">
        <ul className="space-y-2">
          {sections.map((section) => (
            <li key={section.id} className="my-[1rem]">
              <a
                href={`#${section.id}`}
                className="text-blue-500 hover:underline"
              >
                {section.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="md:w-3/4 w-full p-4 overflow-y-auto">
        <div id="section1" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Introduction</h2>
          <p>
            Capival Investment Limited (“Capival”, “Our”, “We”, or “Us”)
            respects your privacy and is committed to protecting your personal
            data. You provide us with your personal data when you use our
            internet-enabled platforms (“platforms”, which include social media
            channels). This Privacy Policy is designed to help you understand
            what information we gather about you, what we use that information
            for, and with whom we share that information. It also sets out your
            rights about your information and who you may contact for more
            information or queries. Please read the following carefully to
            understand our processes and practices regarding your personal data
            and how we will treat it.
          </p>
        </div>
        <div id="section2" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            To whom this Privacy Policy applies and what it covers
          </h2>
          <p>
            This Privacy Policy applies to all persons who use Capival’s
            platforms, access Capival’s products, or interact with Capival in
            connection with any legal, contractual, or business purpose.
            Essentially, this Privacy Policy applies to you if:
          </p>
          <ul className="list-disc ml-4">
            <li>we provide products or services to you;</li>
            <li>you visit our premises or use our website; and/or</li>
            <li>
              we perform any other activities for/with you in connection with
              our business. Our platforms are not intended for children, so we
              do not deliberately collect data relating to children.
            </li>
          </ul>
        </div>
        <div id="section3" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            Other Data Privacy Documents
          </h2>
          <p>
            You must read this Privacy Policy together with any other policy
            document, privacy statement, or fair processing policy we may
            provide on specific occasions when we are collecting or processing
            personal data about you so that you are fully aware of how and why
            we are using your personal data. This privacy policy supplements all
            such other notices and privacy policies and is not intended to
            override them. In this Privacy Policy, we may sometimes collectively
            refer to the handling, collecting, protecting, and storing of
            personal data as the “processing” of personal data.
          </p>
        </div>
        <div id="section4" className="mb-8">
          <h2 className="text-2xl font-bold mb-4"> Other Websites </h2>
          <p>
            Please note that third-party websites that may be linked to this
            website/mobile application are not governed by this Privacy Policy.
            We encourage visitors to review the privacy policy on each of these
            other websites before disclosing any personal data.
          </p>
        </div>
        <div id="section5" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Consent</h2>
          <p>
            You are deemed to have accepted the contents of this Privacy Policy
            when you access our platforms; use our services, content, features,
            technologies or functions offered on our website or digital
            platforms; or otherwise, interact with us. Note that you can
            withdraw your consent at any time. Such withdrawal of consent would
            not affect the lawfulness of processing information done before the
            withdrawal of consent. Where such withdrawal of consent would
            prevent us from providing services to you, we will endeavor to
            inform you.
          </p>
        </div>
        <div id="section6" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            What information do we collect
          </h2>
          <p>
            In the course of providing products or services to you, performing
            due diligence checks in connection with our products or services, or
            in considering the possible products or services we may offer you,
            we generally collect personal data about you. We also typically
            collect personal data about you when you visit or use our platforms.
            We typically collect or obtain your personal data because you give
            it to us (for example, in a form on our website) or because other
            people give that information to us (for example, third-party service
            providers that we use to help operate our website). We may also
            collect or obtain personal data from you because we observe or infer
            that information about you from the way you interact with us.
            Identity Data such as first name, maiden name, last name, username
            or similar identifier, gender, image, biometrics, marital status,
            title, date of birth, BVN, passport number and other national ID
            numbers, employment details (for example, the organization you work
            for, your job title); and family circumstance. Contact Data such as
            address, email address, country of residence, and telephone numbers.
            Financial Data such as bank account and payment card details.
            Transaction Data such as details about payments to and from you and
            other details of products and services you have been provided by us.
            Technical Data such as internet protocol (IP) address, geographic
            location, contact lists, images and files, details of inbound and
            outbound calls, text messages, your login data, browser type, and
            version, time zone setting and location, browser plug-in types and
            versions, operating system and platform, and other technology on the
            devices you use to access this website. Profile Data such as your
            username and password, profile picture, product or service requests
            made by you, your interests, preferences, feedback and survey
            responses. Usage Data such as details of how you use any product or
            service provided by Capival, details of how you like to use our
            website or the interactive products, tools, other technology or
            services we provide to you or our clients, applications installed
            and used on the device websites that you visited before and after
            visiting our website, details of how you like to interact with us,
            and similar information. Marketing and Communications Data such as
            your preferences in receiving marketing from us and our third
            parties and your communication preferences. The types of personal
            data and ‘sensitive’ or ‘special categories’ of personal data that
            we collect will generally vary depending on the nature of the
            products and services that we provide to you and how you use our
            platforms. In some cases, the ‘sensitive’ or ‘special categories’ of
            personal data that we collect may include information collected as
            part of Know-Your-Customer (KYC) and Anti-Money Laundering (AML)
            checks that we must conduct before accepting you as a customer. In
            some rare circumstances, we will also gather other ‘special
            categories’ of personal data about you because you volunteer that
            data to us (for example, it appears in a copy of your resume/CV that
            you upload on our website). In some other circumstances, the
            personal data we collect from you is needed to meet our legal or
            regulatory obligations or to provide you with the products or
            services requested by you. In some cases, we may also collect
            personal data about you indirectly from third parties including but
            not limited to: (i) your employer; (ii) third parties such as
            providers of KYC and AML services which we use to help us meet our
            legal obligations and to help us verify your identity where we
            provide you with products or services; (iii) background check
            providers which we sometimes use to verify your identity when you
            apply to access our products; (iv) third-party service providers
            that help us to operate our platforms; (v) your banks and financial
            institutions; and (vi) credit reference organizations.
          </p>
        </div>
        <div id="section7" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            How We use Information About You
          </h2>
          <p>
            Use of personal data to provide products and services We will use
            your personal data to provide you with products or services. As part
            of this, we may use your personal data in the course of
            correspondence relating to those products or services. Such
            correspondence may be with you, our customers, our service
            providers, or public or judicial authorities with the necessary
            authorization. In many cases, we also use your personal data to
            conduct due diligence checks in advance of providing products or
            services to you and to process an application from you or a
            prospective client to receive products or services from us. If you
            are referred to us by an intermediary or similar third party, we
            also typically use your personal data to assess whether to accept or
            reject your referral to Capival by that intermediary or a similar
            third party. <br />
            <b className="mt-4">
              Use of personal data collected via our platforms
            </b>{" "}
            <br />
            We generally use your personal data collected via our platforms:
            <ul className="list-disc ml-4">
              <li>to manage and improve our products and services;</li>
              <li>
                to manage and improve our platforms (including by drawing up
                statistics on the usage of our platforms);
              </li>
              <li>
                to tailor the content of our platforms to provide you with a
                more personalized experience and draw your attention to
                information about products and services that may be of interest
                to you;
              </li>
              <li>
                to manage and respond to any request that you submit through our
                platforms;
              </li>
              <li>
                to help us learn more about you, the products and services that
                you receive from Capival, and other products and services that
                you or your employer might be interested in receiving; or
              </li>
              <li>
                to correspond with you about services you use or information you
                provide via our platforms. This correspondence is usually with
                you, our service providers, or public or judicial authorities
                with the necessary authorization. <br />
                <b className="mt-4">
                  Use of personal data for other activities that form part of
                  the operation of our business
                </b>
                <br />
                We generally also use your personal data collected via platforms
                for, or in connection with:
              </li>
              <li>applicable legal or regulatory requirements;</li>
              <li>
                requests and communications from public or judicial authorities
                with the necessary authorization;
              </li>
              <li>
                financial accounting, invoicing, and risk analysis purposes;
              </li>
              <li>
                prudent operational management (including credit, fraud and risk
                management, audit, training, and similar administrative
                purposes);
              </li>
              <li>
                client relationship purposes, which involve: (i) contacting you
                to receive feedback on Capival products or services; and (iii)
                contacting you for other marketing or research purposes;
              </li>
              <li>recruitment and business development purposes;</li>
              <li>
                services we receive from our professional advisors, such as
                lawyers, accountants and consultants;
              </li>
              <li>
                arrangements we have in place with intermediaries, brokers and
                other individuals and entities that partner with us;
              </li>
              <li>protecting our rights and those of our customers; and</li>
              <li>meeting our corporate and social responsibilities.</li>
              <br />
              <b>
                The legal grounds we rely on for processing personal data
              </b>{" "}
              <br />
              We will only use your personal data as allowed by law. Usually, we
              will use your personal data in the following circumstances allowed
              under relevant laws: <br />
              <li>Where you consent to our use of your personal data;</li>
              <li>
                Where it is necessary to perform contractual obligations that we
                owe towards you or to take pre-contractual steps at your
                request;
              </li>
              <li>Where we need to comply with a legal obligation;</li>
              <li>
                Where it is necessary to achieve our legitimate business
                objectives;
              </li>
              <li>
                Where we need to protect your vital interest or the vital
                interest of another individual; or
              </li>
              <li>
                Where it is in the interest of the general public to process
                your personal data. To the extent that we process any sensitive
                personal data relating to you for any of the purposes outlined
                above, we will do so either because (1) you have given us your
                explicit consent to process that information; (2) the processing
                is necessary to enter into a binding contract with you or the
                performance of our obligations; (3) the processing is required
                for the protection of your vital interests or of others; (4) the
                processing is necessary for reasons of substantial public
                interest based on applicable law (for example where we are
                required by law or regulatory requirements to process that
                information to ensure we meet our KYC and AML obligations); or
                (5) the processing is necessary for the establishment, exercise
                or defense of legal claims; (6) the processing is necessary for
                reasons of public health; or (7) the processing is necessary for
                archiving, historical, statistical, or scientific research
                purposes.
              </li>
            </ul>
          </p>
        </div>
        <div id="section8" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            To whom we disclose your information
          </h2>
          <p>
            It may be necessary for us to share your personal data with third
            parties. These third parties may include persons or entities
            affiliated with our company, or external third parties such as our
            service providers, and business partners. We generally disclose
            details about you to professional advisors and third parties that
            provide services to us (such as IT systems providers, platform
            providers, financial advisors, consultants including lawyers and
            accountants) and other goods and services providers (such as
            providers of marketing services where we are permitted to disclose
            your personal data to them); competent authorities (including any
            national and/or international regulatory or enforcement body or
            court or other form of tribunal, where we are required to do so by
            applicable law or regulation at their request); a potential buyer,
            transferee, merger partner or seller and their advisers in
            connection with an actual or potential transfer or merger of part or
            all of Capival business or assets, or any associated rights or
            interests, or to acquire a business or enter into a merger with it;
            credit reference agencies or other organizations that help us detect
            criminal activity and incidence of fraud; and any federal, state or
            local government departments and other statutory or public bodies.
            We require all third parties to respect the privacy and ensure the
            security of your personal data, and to treat it by the law. We do
            not allow our third-party service providers to use your personal
            data for their purposes and only permit them to process your
            personal data for specified purposes and by our instructions.
          </p>
        </div>
        <div id="section9" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">International transfers</h2>
          <p>
            We do not routinely transfer your personal data outside of Nigeria.
            Whenever we transfer your personal data out of Nigeria, we ensure
            that a similar degree of protection is afforded to it in the country
            to which it is transferred. In any case, we may transfer data
            outside of Nigeria where the recipient country is on the data
            protection whitelist for the transfer of personal data as set out in
            the Nigeria Data Protection Regulation Framework 2020 or such other
            applicable regulations.
          </p>
        </div>
        <div id="section10" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Marketing communications</h2>
          <p>
            We do not routinely transfer your personal data outside of Nigeria.
            Whenever we transfer your personal data out of Nigeria, we ensure
            that a similar degree of protection is afforded to it in the country
            to which it is transferred. In any case, we may transfer data
            outside of Nigeria where the recipient country is on the data
            protection whitelist for the transfer of personal data as set out in
            the Nigeria Data Protection Regulation Framework 2020 or such other
            applicable regulations.
          </p>
        </div>
        <div id="section11" className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            How long do we keep your personal data
          </h2>
          <p>
            We will hold your personal information on Capival’s systems for as
            long as is necessary to fulfil the purpose for which it was
            collected or to comply with any legal, regulatory, tax, accounting,
            reporting requirements, or internal policy requirements. We endeavor
            to dispose of your personal data once we have concluded that we no
            longer require your personal data in connection with the purpose for
            which it was collected and if disposing of such personal data would
            not expose us to any actions, sanctions or claims. The periods for
            which we hold different categories of data differ. For example, we
            may hold personal data received:
            <ul className="list-disc ml-2">
              <li>
                from unsuccessful job applications for six (6) months after
                notifying the applicant of the outcome;
              </li>
              <li>
                during a request to the help desk for three (3) years after the
                resolution of such request;
              </li>
              <li>
                while handling customer complaints for six (6) years after the
                resolution of the complaint; and
              </li>
              <li>
                from customers about their accounts for six (6) years after the
                successful closure of their accounts. Please note that the
                periods stated above are subject to overriding legal obligations
                and public policy, as well as changing business realities.
                Therefore, we shall endeavor to update the above-stated periods
                accordingly.
              </li>
            </ul>
            <b>Change of purpose</b> <br />
            We will only use your personal data for the purposes we collected it
            unless we reasonably consider that we need to use it for another
            reason and that reason is compatible with the original purpose. If
            you wish to get an explanation as to how the processing for the new
            purpose is compatible with the original purpose, please contact us.
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so. Please note that we may process your personal data without
            your knowledge or consent, in compliance with the above rules, where
            this is required or permitted by law. <br />
            <b>Protection of your personal data</b> <br />
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used, or accessed in an
            unauthorized way, altered, or disclosed. In addition, we limit
            access to your personal data to those employees, agents,
            contractors, and other third parties on a professional need-to-know
            basis. They will only process your personal data on our
            instructions, and they are subject to a duty of confidentiality. We
            have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so. We use a range of
            physical, electronic, and managerial measures to ensure that we keep
            your personal data secure, accurate, and up-to-date. These measures
            include:
            <ul className="list-disc ml-2">
              <li>
                education and training of relevant staff to ensure they are
                aware of our privacy obligations when handling personal data as
                well as training around social engineering, phishing, spear
                phishing, and password risks;
              </li>
              <li>
                administrative and technical controls to restrict access to
                personal data on a ‘need to know’ basis;
              </li>
              <li>
                technological security measures, including firewalls, encryption
                and anti-virus software;
              </li>
              <li>
                physical security measures, such as staff security passes to
                access our premises;
              </li>
              <li>
                external technical assessments, security audits and vendor due
                diligence;
              </li>
              <li>
                endpoint security: anti-virus, portable storage device lockdown,
                restricted administrative privileges
              </li>
              <li>Real-time monitoring of data leakage controls;</li>
              <li>Layered and comprehensive cybersecurity defenses; and</li>
              <li>Security incident reporting and management.</li>
              <li>
                Although we use appropriate security measures once we have
                received your personal data, the transmission of data
              </li>
              <li>
                over the internet (including by e-mail) is never completely
                secure. We endeavor to protect personal data, but we
              </li>
            </ul>
            <span>
              cannot guarantee the security of data transmitted to us or by us.
            </span>
          </p>
          <b>Storage of Your Data</b>
          <p>
            All personal data you provide to us may be stored on our secure
            cloud-based data storage as well as on our premises, off-site based
            locations, and network-accessible storage which include external
            drives only for authorized users. By providing your personal data,
            you agree to this transfer, storage, or processing. We will take all
            steps reasonably necessary to ensure that your data is treated
            securely and by this Privacy Policy. We erase/delete personal data
            in the event of any of the following:
            <ol className="list-decimal ml-4">
              <li>
                The personal data is no longer necessary to the purposes for
                which they were collected or processed;
              </li>
              <li>
                You withdraw your consent or object to the processing and there
                is no overriding lawful basis for the processing;
              </li>
              <li>
                The personal data was unlawfully collected or processed in the
                first place; or
              </li>
              <li>
                In compliance with lawful directives from government regulators.
              </li>
            </ol>
          </p>
          <b>Your rights and how to contact us</b> <br />
          <span>
            You have various rights about your personal data. In particular, you
            have a right to:
          </span>
          <ul className="ml-4 list-disc">
            <li>object to the processing of your personal data;</li>
            <li>request a copy of personal data we hold about you;</li>
            <li>
              ask that we update the personal data we hold about you, or correct
              such personal data that you think is incorrect or incomplete;
            </li>
            <li>
              ask that we delete personal data that we hold about you, or
              restrict how we use such personal data;
            </li>
            <li>
              withdraw consent to our processing of your personal data (to the
              extent such processing is based on consent) To exercise any of
              your rights, or if you have any other questions about our use of
              your personal data, please e-mail{" "}
              <a
                className="text-blue-500"
                href="mailto:dataprotection@capival.com"
              >
                dataprotection@capival.com
              </a>{" "}
              or write to Capival’s Data Protection Officer at 16 Albert Iyorah
              Street Off Admiralty Way, Lekki Phase 1, Lagos, Nigeria.
            </li>
          </ul>
          <b>Breach/Privacy Violation</b>
          <p>
            In the event of a breach of security leading to the accidental or
            unlawful destruction, loss, alteration, unauthorized disclosure of,
            or access to your personal data, we shall within 72 (seventy-two)
            hours of knowing about such breach report the details of the breach
            to the appropriate government agency. Where we ascertain that such
            breach is detrimental to your rights and freedoms to your personal
            data, we shall within 7 (Seven) days of knowledge of the occurrence
            of such breach take steps to inform you of the breach incident, the
            risk to your rights and freedoms resulting from such breach and any
            course of action to remedy said breach.
          </p>
        </div>
      </div>
    </div>
  );
}
