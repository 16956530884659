import React, { useEffect, useState } from "react";
import FAQs from "../components/contactComponents/FAQs";

function Hero() {
  return (
    <div
      className="h-[331px] bg-cover bg-no-repeat grid place-items-center"
      style={{ backgroundImage: 'url("/images/faq-hero.png")' }}
    >
      <h1 className="font-[700] text-[36px] text-center text-white">
        Let’s help you know us better
      </h1>
    </div>
  );
}
const Tabs = ({ color }) => {
  const [openTab, setOpenTab] = useState('capiflex');
  const [capiflex, setCapiflex] = useState([]);
  const [capisave, setCapiSave] = useState([]);
  const [casa, setCasa] = useState([]);
  const [capgrow, setCapigrow] = useState([]);
  
  
  function fetchCapisave() {
     const url =
       "https://capicom.capival.com/api/website/faq?category=capisave";
     const username = "qxDtRrIcuN";
     const password = "rzsl7x16LlH7Waoj1N2Y";

     fetch(url, {
       method: "GET",
       headers: {
         Authorization: "Basic " + btoa(username + ":" + password),
       },
     })
       .then((response) => response.json())
       .then((data) => {
         setCapiSave(data?.faqs);
       })
       .catch((error) => console.error("Error:", error));
  }

  function fetchCapigrow() {
       const url =
         "https://capicom.capival.com/api/website/faq?category=capigrow";
       const username = "qxDtRrIcuN";
       const password = "rzsl7x16LlH7Waoj1N2Y";

       fetch(url, {
         method: "GET",
         headers: {
           Authorization: "Basic " + btoa(username + ":" + password),
         },
       })
         .then((response) => response.json())
         .then((data) => {
           setCapigrow(data?.faqs);
         })
         .catch((error) => console.error("Error:", error));
  }

  function fetchCasa() {
    const url = "https://capicom.capival.com/api/website/faq?category=casa";
    const username = "qxDtRrIcuN";
    const password = "rzsl7x16LlH7Waoj1N2Y";

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa(username + ":" + password),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCasa(data?.faqs);
      })
      .catch((error) => console.error("Error:", error));
  }

  function fetchCapiflex() {
    const url = "https://capicom.capival.com/api/website/faq?category=capiflex";
    const username = "qxDtRrIcuN";
    const password = "rzsl7x16LlH7Waoj1N2Y";

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa(username + ":" + password),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCapiflex(data?.faqs);
      })
      .catch((error) => console.error("Error:", error));
  }

  useEffect(() => {
    fetchCapisave();
    fetchCapigrow();
    fetchCapiflex()
    fetchCasa()
  }, []);


 
  
  // console.log("data====", capiflex);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="max-w-[916px] mx-auto flex gap-10 md:gap-0 justify-center items-center mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px flex-auto text-center">
              <a
                className={
                  "text-xs  w-[200px] mx-auto flex flex-col justify-center items-center font-bold px-5 py-3 shadow-lg rounded leading-normal " +
                  (openTab === "capiflex"
                    ? "text-#33333 bg-[#b0673519]"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab("capiflex");
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <img src="/images/capiflex.png" alt="capiflex" />
                <h1 className="text-[1.5rem] font-bold mt-[.5rem]">Capiflex</h1>
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs  w-[200px] mx-auto flex flex-col justify-center items-center font-bold px-5 py-3 shadow-lg rounded leading-normal " +
                  (openTab === "capisave"
                    ? "text-#33333 bg-[#b0673519]"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab("capisave");
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <img src="/images/capsav.png" alt="capisave" />
                <h1 className="text-[1.5rem] font-bold mt-[.5rem]">Capisave</h1>
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs  w-[200px] mx-auto flex flex-col justify-center items-center font-bold px-5 py-3 shadow-lg rounded leading-normal " +
                  (openTab === "capigrow"
                    ? "text-#33333 bg-[#b0673519]"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab("capigrow");
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <img src="/images/capigrow.png" alt="capigrow" />
                <h1 className="text-[1.5rem] font-bold mt-[.5rem]">CapiGrow</h1>
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs  w-[200px] mx-auto flex flex-col justify-center items-center font-bold px-5 py-3 shadow-lg rounded leading-normal " +
                  (openTab === "casa"
                    ? "text-#33333 bg-[#b0673519]"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab("casa");
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                <img src="/images/asa.png" alt="casa" />
                <h1 className="text-[1.5rem] font-bold mt-[.5rem]">Casa</h1>
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded mt-[20px]">
            <div className="px-4 py-5 flex-auto">
              <h1 className="ml-10 mb-10">
                Frequently Asked Questions <br /> <strong> ({openTab})</strong>
              </h1>
              <div className="tab-content tab-space">
                <div
                  className={openTab === "capiflex" ? "block" : "hidden"}
                  id="link1"
                >
                  <FAQs data={capiflex} />
                </div>
                <div
                  className={openTab === "capisave" ? "block" : "hidden"}
                  id="link2"
                >
                  <FAQs data={capisave} />
                </div>
                <div
                  className={openTab === "capigrow" ? "block" : "hidden"}
                  id="link3"
                >
                  <FAQs data={capgrow} />
                </div>
                <div
                  className={openTab === "casa" ? "block" : "hidden"}
                  id="link4"
                >
                  <FAQs data={casa} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function FaqPage() {
   const pathname = window.location.pathname;
   const onTop = () => {
     window.scrollTo(0, 0);
   };
   useEffect(() => {
     onTop();
   }, [pathname]);
  return (
    <div>
      <Hero />
      <div className="max-w-[1520px] mx-auto py-[100px]">
        <Tabs color="pink" />
      </div>
    </div>
  );
}
