import React from "react";
import Fade from "react-reveal/Fade";

const Hero = () => {
  return (
    <div
      style={{
        backgroundImage: "url('/images/heroBg.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "572px",
      }}
      className=" md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]"
    >
      <Fade bottom>
        <section className="max-w-[1512px] mx-auto">
          <div className="max-w-[695px]">
            <h1 className="md:text-[61px] text-[2rem] text-[#551C24] font-bold md:leading-[74.36px]">
              Capival was <br className="md:block hidden" /> created for the ONE
            </h1>
            <p className="text-[#333333] mt-[40px] text-[1.25rem] font-normal leading-[29.67px]">
              Our entire business model was designed with you in mind. We pride
              ourselves in customer centricity and we believe diverse
              individuals across Nigeria deserve access to financial solutions
              that cater to their deepest desires.
            </p>
          </div>
        </section>
      </Fade>
    </div>
  );
};

export default Hero;
