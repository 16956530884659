import React from "react";
import {Fade} from "react-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./success.css";
import { Autoplay, Pagination } from "swiper";

const SuccessStories = () => {
  return (
    <Fade bottom>
      <div className="wrapper min-h-[600px]">
        <div className="max-w-[1520px] mx-auto lg:PX-[115PX] lg:py-[100px] min-h-[594px] flex justify-center items-center text-white">
          <>
            <Swiper
              pagination={true}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 1500,
                disableOnInteraction: true,
              }}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="max-w-[890px] mx-auto">
                  <h1 className="text-center my-10 text-[36px] font-bold">
                    Saver Success Stories
                  </h1>
                  <p className="text-center text-[20px] my-[60px]">
                    We’re a team of young , vibrant professionals dedicated to
                    making the world a better place, one financial solution at a
                    time. Our values are interwoven into everything we do and
                    we’re always ready to take on any challenge and get things
                    done! 
                  </p>

                  <h4 className="text-center text-[20px] font-extrabold">
                    Adejayi Abimbola Rayosola
                  </h4>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="max-w-[890px] mx-auto">
                  <h1 className="text-center my-10 text-[36px] font-bold">
                    Saver Success Stories
                  </h1>
                  <p className="text-center text-[20px] my-[60px]">
                    We’re a team of young , vibrant professionals dedicated to
                    making the world a better place, one financial solution at a
                    time. Our values are interwoven into everything we do and
                    we’re always ready to take on any challenge and get things
                    done! 
                  </p>

                  <h4 className="text-center text-[20px] font-extrabold">
                    Adejayi Abimbola Rayosola
                  </h4>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="max-w-[890px] mx-auto">
                  <h1 className="text-center my-10 text-[36px] font-bold">
                    Saver Success Stories
                  </h1>
                  <p className="text-center text-[20px] my-[60px]">
                    We’re a team of young , vibrant professionals dedicated to
                    making the world a better place, one financial solution at a
                    time. Our values are interwoven into everything we do and
                    we’re always ready to take on any challenge and get things
                    done! 
                  </p>

                  <h4 className="text-center text-[20px] font-extrabold">
                    Adejayi Abimbola Rayosola
                  </h4>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="max-w-[890px] mx-auto">
                  <h1 className="text-center my-10 text-[36px] font-bold">
                    Saver Success Stories
                  </h1>
                  <p className="text-center text-[20px] my-[60px]">
                    We’re a team of young , vibrant professionals dedicated to
                    making the world a better place, one financial solution at a
                    time. Our values are interwoven into everything we do and
                    we’re always ready to take on any challenge and get things
                    done! 
                  </p>

                  <h4 className="text-center text-[20px] font-extrabold">
                    Adejayi Abimbola Rayosola
                  </h4>
                </div>
              </SwiperSlide>
            </Swiper>
          </>
        </div>
      </div>
    </Fade>
  );
};

export default SuccessStories;
