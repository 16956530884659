import React from "react";
import Button from "../Button";
import Fade  from "react-reveal/Fade";

const CapiSaveHero = () => {
  return (
    <Fade bottom>
      <div className="md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem] ">
        <div className="lg:flex max-w-[1520px] mx-auto">
          <div className="flex-1">
            <h1 className="text-[#551C24] lg:text-[60px] text-[30px] md:leading-[74.36px] leading-10 font-bold">
              A smarter way <br /> to save
            </h1>
            <p className="text-[#333333] max-w-[587px] lg:text-[20px] text-[16px] mt-[40px] font-normal">
              Saving money doesn’t have to be challenging. We’ve made saving
              effortless with our automatic savings tools that help you reach
              your goals faster.
            </p>
            <div className="flex flex-wrap mt-[20px] gap-[20px] mb-10">
              <img
                src="/images/g2.png"
                alt="googleBtn"
                className="w-[126px] h-[35px] object-cover"
              />
              <img
                src="/images/i2.png"
                alt="IOSBtn"
                className="w-[126px] h-[35px] object-cover"
              />
                <img src="/images/qrc.png" alt="QRC code" />
            </div>
            <Button title="Sign Up Here" />
          </div>
          <div className="flex-1">
            <div className="max-w-[624px] max-h-[540px] relative lg:mt-0 mt-10">
              <img
                src="/images/capisaveHero.png"
                alt="Capisave hero"
                className="w-full h-full object-cover"
              />
              <img
                src="/images/heroCard.png"
                alt="card"
                className="absolute bottom-0 lg:inline hidden left-[-100px] w-[313px] h-[151px] object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default CapiSaveHero;
