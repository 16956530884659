import React from "react";
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import Button from "../Button";
export default function HeroSecion() {
  return (
    <div className=" md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
      <Fade bottom>
        <div className="lg:flex max-w-[1520px] mx-auto flex-wrap items-center gap-[20px]">
          <section className="flex-1">
            <div>
              <h1 className="lg:text-[50px] md:text-[40px] text-[30px] font-bold md:leading-[71.56px] leading-10">
                Empowering your financial future
              </h1>
              <p className="lg:text-[20px] text-[16px] font-normal leading-[27px] text-[#333333] my-[18px]">
                We exist to help your financial journey from start to finish.
                Just tell us your goals and we’ll make it happen. 
              </p>
              <Button
                title="Sign Up Here"
                url="https://online.capival.com/create-profile"
              />
              <div className="flex items-center my-[40px] gap-[23px]">
                <Link to="/">
                  <img
                    src="/images/googlePlayBtn.png"
                    alt="Google play Button"
                  />
                </Link>
                <Link to="https://apps.apple.com/ng/app/capival/id6444798638">
                  <img src="/images/iosBtn.png" alt="IOS Button " />
                </Link>
              </div>
              <div className="my-[20px]">
                <img src="/images/qrc.png" alt="QRC code" />
               
              </div>
            </div>
          </section>
          <section className="flex-1">
            <div className="md:w-[483px] w-[80%] mx-auto md:max-h-[41rem] ">
              <img
                src="/images/rightImg.png"
                alt="Hero-imag"
                className="w-full object-cover"
              />
            </div>
          </section>
        </div>
      </Fade>
    </div>
  );
} 
