import React from "react";
import Fade from "react-reveal/Fade";

const Card = ({ title, desc, bg }) => {
  return (
    <div
      className="lg:w-[534px] max-w-[1520px]  lg:min-h-[189px] w-[19rem] mx-auto lg:px-[39px] lg:py-[39px] p-5 rounded-md flex justify-center items-center"
      style={{ background: `${bg}` }}
    >
      <p>
        <b>{title}:</b> {desc}
      </p>
    </div>
  );
};
const Vision = () => {
  return (
    <Fade bottom>
      <div className="lg:flex justify-between items-center max-w-[1520px] mx-auto">
        <div className="lg:flex-[33.3%] lg:relative top-0 bottom-0 lg:flex justify-center items-center">
          <div className="lg:ml-[85px] m-10 lg:absolute left-[-20px] flex flex-col gap-[78px]">
            <Card
              bg="#D7DFEF"
              title="Our Vision"
              desc=" To be the leading firm inspiring financial freedom to individuals and corporates in Nigeria."
            />
            <Card
              bg="#FCD890"
              title="Our Mission"
              desc="To empower customers with innovative products and technology to harness their financial power to improve their lives and communities at large."
            />
          </div>
        </div>
        <div className="flex-[66.6%]">
          <div>
            <div className="lg:h-[34.4rem] h-[26rem] w-full">
              {/* <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/uwsoyTPAduY"
                title="15 Things You Didn’t Know About the Fintech Industry"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/DVQSwDBAys8"
                title="CNTV MEET THE BOSS: TOKUNBO AJETUNMOBI, MD/CEO - CAPIVAL INVESTMENT"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Vision;
