import React from "react";
import Fade from "react-reveal/Fade";

export default function Protfolio() {
  return (
    <Fade bottom>
      <div className="md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem] bg-[#F6F6F6]">
        <div className="max-w-[1520px] mx-auto">
          <div className="pt-[77px]">
            <h1 className="md:text-[45px] text-[22px] font-bold text-center ">
              <span className="text-[#E21F27] md:text-[60px] text-[30px]">
                N2 Billion+{" "}
              </span>
              Portfolio Size
            </h1>
            <p className="text-[18px] font-normal leading-[29px] text-center max-w-[879px] mx-auto my-[20px]">
              Maximize your money’s potential with smart investment
              opportunities. With us, you can relax and watch your money do the
              work.
            </p>
            <div className="flex justify-center items-center">
              <img
                src="/images/people.png"
                alt="people"
                className="object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
