import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { GrClose } from "react-icons/gr";

export default function Header() {
  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className="bg-[#FFFF] py-[16px] lg:px-[64px] px-[1rem]  sticky top-0 z-50">
      <header className="max-w-[1512px] mx-auto">
        <nav className="flex justify-between items-center w-full">
          <Link to="/" className="md:w-[191px] w-[130px] cursor-pointer">
            <img
              src="/images/logo.png"
              alt="Capival Investment Logo"
              className="w-full object-cover"
            />
          </Link>
          <button className="lg:hidden block" onClick={() => setOpen(!open)}>
            {open ? <GrClose fontSize="24px" /> : <BiMenu fontSize="24px" />}
          </button>
          {open ? (
            <div className="bg-white absolute top-0 left-0 right-0 bottom-0 w-full min-h-[100vh] lg:hidden p-2">
              <div className="px-3 flex my-5 justify-between items-center">
                <Link
                  to="/"
                  className="w-[120px] cursor-pointer"
                  onClick={() => setOpen(false)}
                >
                  <img
                    src="/images/logo.png"
                    alt="Capival Investment Logo"
                    className="w-[120px] object-cover"
                  />
                </Link>
                <GrClose fontSize="24px" onClick={() => setOpen(!open)} />
              </div>
              <ul
                className="flex flex-col items-start gap-[30px] font-medium text-[#3D3333]"
                // onClick={() => setOpen(false)}
              >
                <li
                  className="hover:border-b-4 border-[#E91C24bb] hover:text-[#E91C24bb] px-3 py-1"
                  onClick={() => setOpen(false)}
                >
                  <Link to="/">Home</Link>
                </li>
                <li
                  className="hover:border-b-4 border-[#E91C24bb] hover:text-[#E91C24bb] px-3 py-1"
                  onClick={() => setOpen(false)}
                >
                  <Link to="/about">About Us</Link>
                </li>
                <li
                  className="hover:border-b-4 border-[#E91C24bb] hover:text-[#E91C24bb] px-3 py-1"
                  onClick={() => setDropdown(!dropdown)}
                  onMouseLeave={() => setDropdown(!dropdown)}
                >
                  <span className="flex items-center gap-[6px] cursor-pointer">
                    Our Products{" "}
                    <svg
                      width="11"
                      height="7"
                      viewBox="0 0 11 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.725862 0.868521C0.913389 0.68105 1.1677 0.575734 1.43286 0.575734C1.69803 0.575734 1.95233 0.68105 2.13986 0.868521L5.43286 4.16152L8.72586 0.868521C8.81811 0.773011 8.92845 0.696828 9.05046 0.644419C9.17246 0.59201 9.30368 0.564424 9.43646 0.56327C9.56924 0.562116 9.70092 0.587418 9.82382 0.637699C9.94671 0.68798 10.0584 0.762233 10.1523 0.856125C10.2461 0.950018 10.3204 1.06167 10.3707 1.18457C10.421 1.30746 10.4463 1.43914 10.4451 1.57192C10.444 1.7047 10.4164 1.83592 10.364 1.95792C10.3116 2.07993 10.2354 2.19027 10.1399 2.28252L6.13986 6.28252C5.95233 6.46999 5.69803 6.57531 5.43286 6.57531C5.1677 6.57531 4.91339 6.46999 4.72586 6.28252L0.725862 2.28252C0.53839 2.09499 0.433075 1.84068 0.433075 1.57552C0.433075 1.31036 0.53839 1.05605 0.725862 0.868521Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {dropdown && (
                    <ul className="my-5 ml-5 text-[#3D3333]">
                      <Link to="/product/capiflex">
                        <li
                          className="mb-3 hover:text-blue-600 hover:underline"
                          onClick={() => setOpen(false)}
                        >
                          Capiflex
                        </li>
                      </Link>
                      <Link to="/product/capigrow">
                        <li
                          className="mb-3 hover:text-blue-600 hover:underline"
                          onClick={() => setOpen(false)}
                        >
                          Capigrow
                        </li>
                      </Link>
                      <li
                        className="mb-3 hover:text-blue-600 hover:underline"
                        onClick={() => setOpen(false)}
                      >
                        <Link to="/product/capisave">Capisave</Link>
                      </li>
                      <Link to="/product/casa">
                        <li
                          className="my-3 hover:text-blue-600 hover:underline"
                          onClick={() => setOpen(false)}
                        >
                          Casa
                        </li>
                      </Link>
                    </ul>
                  )}
                </li>
                <li
                  className="hover:border-b-4 border-[#E91C24bb] hover:text-[#E91C24bb] px-3 py-1"
                  onClick={() => setOpen(false)}
                >
                  <Link to="/contact">Contact</Link>
                </li>
                <li className="border-none border-[#00000092] ml-2">
                  <Link
                    to="https://online.capival.com
"
                    className="bgGradient flex items-center px-[2rem] py-[10px] text-[#FFFFFF] font-normal"
                  >
                    <svg
                      width="13"
                      height="15"
                      viewBox="0 0 13 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.17746 6.72791V5.03305C2.17746 3.90928 2.62388 2.83155 3.4185 2.03693C4.21312 1.24231 5.29085 0.795898 6.41461 0.795898C7.53837 0.795898 8.61611 1.24231 9.41073 2.03693C10.2053 2.83155 10.6518 3.90928 10.6518 5.03305V6.72791C11.1013 6.72791 11.5324 6.90647 11.8502 7.22432C12.1681 7.54217 12.3466 7.97326 12.3466 8.42276V12.6599C12.3466 13.1094 12.1681 13.5405 11.8502 13.8584C11.5324 14.1762 11.1013 14.3548 10.6518 14.3548H2.17746C1.72796 14.3548 1.29687 14.1762 0.979018 13.8584C0.66117 13.5405 0.482605 13.1094 0.482605 12.6599V8.42276C0.482605 7.97326 0.66117 7.54217 0.979018 7.22432C1.29687 6.90647 1.72796 6.7279 2.17746 6.72791ZM8.9569 5.03305V6.72791H3.87232V5.03305C3.87232 4.35879 4.14017 3.71215 4.61694 3.23538C5.09371 2.75861 5.74036 2.49076 6.41461 2.49076C7.08887 2.49076 7.73551 2.75861 8.21228 3.23538C8.68905 3.71215 8.9569 4.35879 8.9569 5.03305Z"
                        fill="white"
                      />
                    </svg>
                    Online Banking
                  </Link>
                </li>
              </ul>
            </div>
          ) : (
            <ul className="lg:flex items-center gap-[.5rem] font-medium text-[#3D3333] text-[.9rem] hidden">
              <li className="hover:border-b-4 border-[#E91C24bb] hover:text-[#E91C24bb] px-3 py-1">
                <Link to="/">Home</Link>
              </li>
              <li className="hover:border-b-4 border-[#E91C24bb] hover:text-[#E91C24bb] px-3 py-1">
                <Link to="/about">About Us</Link>
              </li>
              <li
                className="px-3 py-1 relative"
                onClick={() => setDropdown(!dropdown)}
                onMouseLeave={() => setDropdown(false)}
              >
                <span className="flex items-center gap-[6px] cursor-pointer">
                  Our Products{" "}
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.725862 0.868521C0.913389 0.68105 1.1677 0.575734 1.43286 0.575734C1.69803 0.575734 1.95233 0.68105 2.13986 0.868521L5.43286 4.16152L8.72586 0.868521C8.81811 0.773011 8.92845 0.696828 9.05046 0.644419C9.17246 0.59201 9.30368 0.564424 9.43646 0.56327C9.56924 0.562116 9.70092 0.587418 9.82382 0.637699C9.94671 0.68798 10.0584 0.762233 10.1523 0.856125C10.2461 0.950018 10.3204 1.06167 10.3707 1.18457C10.421 1.30746 10.4463 1.43914 10.4451 1.57192C10.444 1.7047 10.4164 1.83592 10.364 1.95792C10.3116 2.07993 10.2354 2.19027 10.1399 2.28252L6.13986 6.28252C5.95233 6.46999 5.69803 6.57531 5.43286 6.57531C5.1677 6.57531 4.91339 6.46999 4.72586 6.28252L0.725862 2.28252C0.53839 2.09499 0.433075 1.84068 0.433075 1.57552C0.433075 1.31036 0.53839 1.05605 0.725862 0.868521Z"
                      fill="black"
                    />
                  </svg>
                </span>
                {dropdown && (
                  <ul
                    className="mt-6 w-full ml-5 text-[#3D3333] absolute text-[16px] left-0 top-0 z-40 bg-white rounded-md pt-4 overflow-hidden"
                    onClick={() => setDropdown(false)}
                  >
                    <Link to="/product/capiflex">
                      <li className="mb-3 hover:bg-[#E91C24bb] hover:text-white px-4 cursor-pointer rounded-t-md">
                        Capiflex
                      </li>
                    </Link>
                    <Link to="/product/capigrow">
                      <li className="mb-3 hover:bg-[#E91C24bb] hover:text-white px-4 cursor-pointer">
                        Capigrow
                      </li>
                    </Link>
                    <Link to="/product/capisave">
                      <li className="hover:bg-[#E91C24bb] hover:text-white px-4 cursor-pointer">
                        Capisave
                      </li>
                    </Link>
                    <Link to="/product/casa">
                      <li className="hover:bg-[#E91C24bb] hover:text-white px-4 cursor-pointer mt-[10px]">
                        Casa
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
              <li className="hover:border-b-4 border-[#E91C24bb] hover:text-[#E91C24bb] px-3 py-1">
                <Link to="/contact">Contact</Link>
              </li>
              <li className="border-l border-[#00000092] ml-2">
                <Link
                  to="https://online.capival.com
"
                  className="bgGradient flex items-center px-[2rem] py-[12px] text-[#FFFFFF] font-normal ml-[23px]"
                >
                  <svg
                    width="13"
                    height="15"
                    viewBox="0 0 13 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.17746 6.72791V5.03305C2.17746 3.90928 2.62388 2.83155 3.4185 2.03693C4.21312 1.24231 5.29085 0.795898 6.41461 0.795898C7.53837 0.795898 8.61611 1.24231 9.41073 2.03693C10.2053 2.83155 10.6518 3.90928 10.6518 5.03305V6.72791C11.1013 6.72791 11.5324 6.90647 11.8502 7.22432C12.1681 7.54217 12.3466 7.97326 12.3466 8.42276V12.6599C12.3466 13.1094 12.1681 13.5405 11.8502 13.8584C11.5324 14.1762 11.1013 14.3548 10.6518 14.3548H2.17746C1.72796 14.3548 1.29687 14.1762 0.979018 13.8584C0.66117 13.5405 0.482605 13.1094 0.482605 12.6599V8.42276C0.482605 7.97326 0.66117 7.54217 0.979018 7.22432C1.29687 6.90647 1.72796 6.7279 2.17746 6.72791ZM8.9569 5.03305V6.72791H3.87232V5.03305C3.87232 4.35879 4.14017 3.71215 4.61694 3.23538C5.09371 2.75861 5.74036 2.49076 6.41461 2.49076C7.08887 2.49076 7.73551 2.75861 8.21228 3.23538C8.68905 3.71215 8.9569 4.35879 8.9569 5.03305Z"
                      fill="white"
                    />
                  </svg>
                  Online Banking
                </Link>
              </li>
            </ul>
          )}
        </nav>
      </header>
    </div>
  );
}
