import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const Level = () => {
  return (
    <div className=" md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
      <Fade bottom>
        <section className="max-w-[1512px] mx-auto md:mt-[10px]">
          <div className="flex flex-col-reverse lg:flex-row justify-between md:gap-[64px] gap-[20px]">
            <div className="flex-1">
              <div className="md:max-w-[412px] w-[250px] mx-auto md:h-[495px] h-[300px]">
                <img
                  src="/images/level.png"
                  alt="level"
                  className="w-full h- [400px] object-cover"
                />
              </div>
            </div>
            <div className="flex-1">
              <h1 className="md:text-[2.75rem] text-[1.8rem] font-extrabold leading-9 md:leading-[51px] text-[#000000]">
                Unlock new levels of Financial Flexibility
              </h1>
              <p className="my-[25px] text-[1.2rem] text-[#333333] leading-[29px] font-normal ">
                We’re bridging the gap between you and your financial goals by
                connecting you to a bigger, brighter future. With our lending
                and wealth generation tools, your finances are one less
                problem.  Get that degree, take a vacation, rent your dream
                apartment, invest for generations to come, sort out unexpected
                expenses and more. With Capival, life is{" "}
                <b className="text-[#000000]"> easy peasy.</b>
              </p>
              <Link to="/about" className="bgGradient px-[30px] py-[11px] text-white my-[10px]">
                Get to Know Capival
              </Link>
            </div>
          </div>
        </section>
      </Fade>
    </div>
  );
};

export default Level;
