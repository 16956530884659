import React from "react";
import { Link } from "react-router-dom";

const Arrow = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99852 0.808465C10.1689 0.638155 10.3999 0.54248 10.6408 0.54248C10.8817 0.54248 11.1127 0.638155 11.2831 0.808465L14.9169 4.44229C15.0872 4.61265 15.1829 4.84368 15.1829 5.08457C15.1829 5.32546 15.0872 5.55649 14.9169 5.72685L11.2831 9.36068C11.1117 9.52616 10.8823 9.61773 10.6441 9.61566C10.4059 9.61359 10.178 9.51805 10.0096 9.34961C9.84115 9.18118 9.74561 8.95333 9.74354 8.71513C9.74147 8.47694 9.83303 8.24746 9.99852 8.07612L12.0816 5.99303H1.55622C1.31529 5.99303 1.08422 5.89732 0.913847 5.72695C0.743478 5.55658 0.647766 5.32551 0.647766 5.08457C0.647766 4.84363 0.743478 4.61257 0.913847 4.4422C1.08422 4.27183 1.31529 4.17612 1.55622 4.17612H12.0816L9.99852 2.09302C9.82821 1.92266 9.73253 1.69163 9.73253 1.45074C9.73253 1.20985 9.82821 0.978825 9.99852 0.808465Z"
        fill="#8C2529"
      />
    </svg>
  );
};
const ProductCard = ({ image, title, details, url, bg }) => {
  return (
    <div
      className="card-bg mx-auto px-[2rem] py-4 max-w-[310px] cursor-pointer"
      style={{ background: `${bg}` }}
    >
      <img
        src={image}
        alt={`${title}-icon`}
        className="w-[36px] h-[33px] object-contain my-3"
      />
      <h1 className="text-[1.5rem] font-bold mt-[.5rem]">{title}</h1>
      <p className="text-[.8rem] text-[#333333] leading-[20.54px] font-normal my-[.8rem] line-clamp-3">
        {details}
      </p>
      <Link
        to={url}
        className="flex items-center gap-2 text-[#8C2529] text-[.9rem] font-bold my-[4px]"
      >
        Learn More <Arrow />
      </Link>
    </div>
  );
};

export default ProductCard;
