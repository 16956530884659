import React from "react";
import { useState } from "react";
import Fade from "react-reveal";
const Close = () => {
  return (
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H12V2H0V0Z" fill="#E21F27" />
    </svg>
  );
};
const Open = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.20749 0H6.79251V5.20749H12V6.79251H6.79251V12H5.20749V6.79251H0V5.20749H5.20749V0Z"
        fill="#E21F27"
      />
    </svg>
  );
};
export default function FAQs({ data }) {
  const [openFaq, setOpenFaq] = useState(false);

  function handleOpenFaq(idx) {
    if (idx) {
      setOpenFaq(!openFaq)
    }
  }
  return (
    <Fade bottom>
      {data ? (
        <div className="">
          {data.map((faq) => (
            <div
              className="flex gap-8 transition-all cursor-pointer pb-[30px] border-b md:mb-[40px] mb-10"
              onClick={() => {
                setOpenFaq(!openFaq);
              }}
            >
              <div className="flex-shrink-0">
                <button
                  onClick={() => handleOpenFaq(faq.id)}
                  className="ml-10"
                >
                  {openFaq ? <Close /> : <Open />}
                </button>
              </div>
              <div>
                <h4 className="text-[1rem] font-semibold text-[#000000]">
                  {faq.question}
                </h4>
                {openFaq &&  (
                  <p className="mt-[14px] transition-all">{faq.answer}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <h1 className="text-center text-[20px]">No question</h1>
        </div>
      )}
    </Fade>
  );
}
