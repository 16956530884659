import React from "react";
import Fade from "react-reveal/Fade";

const CommitmentSvg = () => {
  return (
    <svg
      width="63"
      height="61"
      viewBox="0 0 63 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.673 7.02781V37.6111H22.3225V7.02781H40.673ZM40.673 0.909943H22.3225C20.6999 0.909943 19.1438 1.5545 17.9965 2.70183C16.8491 3.84915 16.2046 5.40525 16.2046 7.02781V37.6111C16.2046 38.4145 16.3628 39.21 16.6703 39.9523C16.9777 40.6946 17.4284 41.369 17.9965 41.9371C18.5646 42.5052 19.239 42.9558 19.9813 43.2633C20.7235 43.5707 21.519 43.729 22.3225 43.729H40.673C42.2956 43.729 43.8517 43.0844 44.999 41.9371C46.1463 40.7898 46.7909 39.2337 46.7909 37.6111V7.02781C46.7909 6.2244 46.6327 5.42886 46.3252 4.6866C46.0178 3.94435 45.5671 3.26992 44.999 2.70183C44.4309 2.13373 43.7565 1.68309 43.0142 1.37564C42.272 1.06819 41.4764 0.909943 40.673 0.909943Z"
        fill="#8C2529"
      />
      <path
        d="M40.6731 44.6419H22.3225C20.4585 44.6403 18.6712 43.899 17.3533 42.5808C16.0353 41.2626 15.2944 39.4752 15.2932 37.6111V7.02782C15.2952 5.16454 16.0363 3.37815 17.3538 2.06062C18.6714 0.74308 20.4578 0.00200719 22.321 0L40.6716 0C42.5349 0.00200719 44.3213 0.74308 45.6388 2.06062C46.9563 3.37815 47.6974 5.16454 47.6994 7.02782V37.6111C47.6982 39.4746 46.9577 41.2616 45.6404 42.5797C44.3231 43.8979 42.5367 44.6395 40.6731 44.6419ZM22.321 1.81989C20.9403 1.8215 19.6166 2.3707 18.6402 3.34703C17.6639 4.32336 17.1147 5.64708 17.1131 7.02782V37.6111C17.1147 38.9918 17.6639 40.3156 18.6402 41.2919C19.6166 42.2682 20.9403 42.8174 22.321 42.819H40.6716C42.0526 42.8178 43.3767 42.2688 44.3534 41.2924C45.33 40.3161 45.8794 38.9921 45.881 37.6111V7.02782C45.8794 5.64708 45.3302 4.32336 44.3539 3.34703C43.3776 2.3707 42.0539 1.8215 40.6731 1.81989H22.321ZM41.5815 38.521H21.4111V6.11787H41.5815V38.521ZM23.231 36.7012H39.7617V7.93776H23.231V36.7012Z"
        fill="#E21F27"
      />
      <path
        d="M55.9616 11.6155H7.02778C5.40522 11.6155 3.84912 12.26 2.70179 13.4073C1.55447 14.5547 0.909912 16.1108 0.909912 17.7333V45.2622C0.909912 46.8848 1.55447 48.4409 2.70179 49.5882C3.84912 50.7355 5.40522 51.3801 7.02778 51.3801H55.9616C57.5842 51.3801 59.1403 50.7355 60.2876 49.5882C61.435 48.4409 62.0795 46.8848 62.0795 45.2622V17.7333C62.0795 16.1108 61.435 14.5547 60.2876 13.4073C59.1403 12.26 57.5842 11.6155 55.9616 11.6155Z"
        fill="#8C2529"
      />
      <path
        d="M55.9616 11.6155H7.02778C3.64885 11.6155 0.909912 14.9125 0.909912 18.9784V52.1126C0.909912 56.1785 3.64885 59.4756 7.02778 59.4756H55.9616C59.3406 59.4756 62.0795 56.1785 62.0795 52.1126V18.9784C62.0856 14.9125 59.3451 11.6155 55.9616 11.6155Z"
        fill="#8C2529"
      />
      <path
        opacity="0.5"
        d="M62.0856 17.8046V21.687C62.085 20.8835 61.9261 20.088 61.618 19.3458C61.3099 18.6037 60.8586 17.9295 60.2899 17.3618C59.7213 16.7942 59.0463 16.344 58.3037 16.0372C57.561 15.7304 56.7652 15.5729 55.9616 15.5737H7.02778C5.40601 15.5737 3.85059 16.2177 2.7034 17.364C1.55621 18.5103 0.911118 20.0653 0.909912 21.687V17.8046C0.909912 16.182 1.55447 14.6259 2.70179 13.4786C3.84912 12.3313 5.40522 11.6867 7.02778 11.6867H55.9616C56.7656 11.6859 57.5618 11.8436 58.3047 12.1507C59.0477 12.4578 59.7228 12.9083 60.2915 13.4765C60.8603 14.0446 61.3115 14.7193 61.6193 15.462C61.9271 16.2046 62.0856 17.0007 62.0856 17.8046Z"
        fill="#FF9EA2"
      />
      <path
        d="M55.9617 60.3855H7.02782C3.15296 60.3855 0 56.6745 0 52.1126V18.9784C0 14.4166 3.15296 10.7055 7.02782 10.7055H55.9617C59.8365 10.7055 62.9895 14.4166 62.9895 18.9784V52.1126C62.9956 56.6745 59.8426 60.3855 55.9617 60.3855ZM7.02782 12.5254C4.15542 12.5254 1.81989 15.4206 1.81989 18.9784V52.1126C1.81989 55.6705 4.15542 58.5656 7.02782 58.5656H55.9617C58.8326 58.5656 61.1696 55.6705 61.1696 52.1126V18.9784C61.1696 15.4206 58.8341 12.5254 55.9617 12.5254H7.02782Z"
        fill="#E21F27"
      />
      <path
        d="M20.072 27.9535V31.5372C20.072 32.8392 20.5892 34.0879 21.5099 35.0085C22.4305 35.9292 23.6792 36.4464 24.9812 36.4464H38.0131C39.3148 36.4456 40.563 35.9278 41.483 35.0069C42.403 34.086 42.9196 32.8374 42.9192 31.5357V27.9535"
        fill="#E7E7E7"
      />
      <path
        d="M20.072 28.2402H42.9223V30.2557H20.072V28.2402Z"
        fill="#CED0D0"
      />
      <path
        d="M0.909912 27.0436H62.0856V28.8635H0.909912V27.0436Z"
        fill="#E21F27"
      />
      <path
        d="M38.0129 37.3548H24.981C23.4381 37.3532 21.959 36.7396 20.868 35.6486C19.7771 34.5577 19.1635 33.0785 19.1619 31.5357V27.9535H20.9818V31.5372C20.983 32.5975 21.4047 33.614 22.1544 34.3637C22.9042 35.1135 23.9207 35.5352 24.981 35.5364H38.0129C39.0729 35.5344 40.0889 35.1121 40.838 34.3621C41.5872 33.6122 42.0083 32.5957 42.0091 31.5357V27.9535H43.829V31.5372C43.827 33.0792 43.2136 34.5576 42.1234 35.6481C41.0331 36.7386 39.555 37.3524 38.0129 37.3548Z"
        fill="#E21F27"
      />
      <path
        d="M29.4822 31.5205H33.5132V32.7338H29.4822V31.5205Z"
        fill="#E21F27"
      />
      <path
        d="M58.9786 43.7806C59.2993 43.7726 59.5192 42.1723 59.4698 40.2063C59.4204 38.2402 59.1203 36.653 58.7997 36.6611C58.479 36.6691 58.2591 38.2694 58.3085 40.2354C58.3579 42.2015 58.6579 43.7887 58.9786 43.7806Z"
        fill="white"
      />
      <path
        d="M58.7829 35.0305C59.1036 35.0225 59.3436 34.219 59.3189 33.236C59.2942 32.253 59.0141 31.4627 58.6935 31.4707C58.3728 31.4788 58.1328 32.2822 58.1575 33.2652C58.1822 34.2482 58.4622 35.0386 58.7829 35.0305Z"
        fill="white"
      />
    </svg>
  );
};
const LoyaltySvg = () => {
  return (
    <svg
      width="57"
      height="51"
      viewBox="0 0 57 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.239 44.4137H32.1583V50.1847C32.1583 50.1958 32.1562 50.2067 32.1519 50.2169C32.1477 50.2272 32.1415 50.2364 32.1337 50.2443C32.1259 50.2521 32.1166 50.2583 32.1064 50.2625C32.0962 50.2667 32.0852 50.2689 32.0741 50.2689H25.3232C25.3009 50.2689 25.2795 50.2601 25.2637 50.2443C25.2479 50.2285 25.239 50.2071 25.239 50.1847V44.4137Z"
        fill="#8C2529"
      />
      <path d="M25.239 45.7363H32.163V47.0098H25.239V45.7363Z" fill="#E21F27" />
      <path
        d="M32.1643 50.7402C32.0247 50.7402 31.8909 50.6848 31.7922 50.5861C31.6935 50.4874 31.6381 50.3535 31.6381 50.214V44.961H25.7653V50.214C25.7653 50.3535 25.7099 50.4874 25.6112 50.5861C25.5125 50.6848 25.3787 50.7402 25.2391 50.7402C25.0996 50.7402 24.9657 50.6848 24.867 50.5861C24.7683 50.4874 24.7129 50.3535 24.7129 50.214V43.9086H32.6905V50.214C32.6902 50.3534 32.6347 50.4871 32.5361 50.5857C32.4374 50.6843 32.3038 50.7399 32.1643 50.7402Z"
        fill="#25252D"
      />
      <path
        d="M23.2932 49.7626H34.1476V50.815H23.2932V49.7626Z"
        fill="#25252D"
      />
      <path d="M20.551 49.7626H21.8374V50.815H20.551V49.7626Z" fill="#25252D" />
      <path d="M35.606 49.7626H37.0899V50.815H35.606V49.7626Z" fill="#25252D" />
      <path
        d="M53.0042 5.37924H4.39929C2.48308 5.37924 0.929688 6.93263 0.929688 8.84884V41.7475C0.929688 43.6637 2.48308 45.2171 4.39929 45.2171H53.0042C54.9204 45.2171 56.4738 43.6637 56.4738 41.7475V8.84884C56.4738 6.93263 54.9204 5.37924 53.0042 5.37924Z"
        fill="#8C2529"
      />
      <path
        d="M53.5398 44.9295C53.4509 44.9376 53.3608 44.9412 53.2708 44.9412H4.66709C4.21136 44.9413 3.76006 44.8517 3.33897 44.6774C2.91789 44.5031 2.53526 44.2475 2.21296 43.9253C1.89065 43.6031 1.63498 43.2206 1.46054 42.7996C1.2861 42.3786 1.19632 41.9273 1.19632 41.4716L0.818604 8.01506C0.818604 6.02592 2.84634 5.48799 2.85803 5.37924C5.93238 6.63166 5.26232 9.83231 6.95795 12.0109C9.1798 14.8525 7.28421 20.7872 6.65741 23.8709C5.70435 28.5637 4.128 33.5536 4.38176 38.389C4.67762 43.9694 11.9548 45.0406 16.3716 45.3586C22.2046 45.7773 28.0796 45.534 33.881 44.9914C40.2332 44.3986 47.3981 45.5703 53.5398 44.9295Z"
        fill="#E21F27"
      />
      <path
        d="M4.82959 8.77516H52.5738V37.7283H4.82959V8.77516Z"
        fill="#373635"
      />
      <path
        opacity="0.5"
        d="M52.5738 8.77516H4.82959V10.8216H10.3527V37.7283H12.3991V10.8216H52.5738V8.77516Z"
        fill="#21201F"
      />
      <path
        d="M53.6215 45.7434H3.78171C2.88599 45.7424 2.02722 45.3862 1.39385 44.7528C0.760484 44.1195 0.404249 43.2607 0.40332 42.365V8.23139C0.403939 7.33547 0.760038 6.4764 1.39344 5.84278C2.02685 5.20915 2.88579 4.85276 3.78171 4.85183H53.6215C54.5174 4.85276 55.3763 5.20915 56.0097 5.84278C56.6431 6.4764 56.9992 7.33547 56.9999 8.23139V42.365C56.9989 43.2607 56.6427 44.1195 56.0093 44.7528C55.376 45.3862 54.5172 45.7424 53.6215 45.7434ZM3.78171 5.90429C3.16492 5.90522 2.57367 6.15073 2.13764 6.58698C1.70161 7.02323 1.4564 7.6146 1.45578 8.23139V42.365C1.45671 42.9816 1.70206 43.5726 2.13805 44.0086C2.57405 44.4446 3.16512 44.69 3.78171 44.6909H53.6215C54.2381 44.69 54.8291 44.4446 55.2651 44.0086C55.7011 43.5726 55.9465 42.9816 55.9474 42.365V8.23139C55.9468 7.6146 55.7016 7.02323 55.2655 6.58698C54.8295 6.15073 54.2383 5.90522 53.6215 5.90429H3.78171Z"
        fill="#25252D"
      />
      <path
        d="M28.7018 20.1827C28.6242 20.1827 28.5499 20.1519 28.495 20.097C28.4402 20.0422 28.4094 19.9678 28.4094 19.8903V12.8189C28.4094 12.7414 28.4402 12.6671 28.495 12.6122C28.5499 12.5574 28.6242 12.5266 28.7018 12.5266C28.7793 12.5266 28.8537 12.5574 28.9085 12.6122C28.9633 12.6671 28.9941 12.7414 28.9941 12.8189V19.8903C28.9941 19.9678 28.9633 20.0422 28.9085 20.097C28.8537 20.1519 28.7793 20.1827 28.7018 20.1827Z"
        fill="white"
      />
      <path
        d="M28.7018 23.6148C28.6242 23.6148 28.5499 23.584 28.495 23.5292C28.4402 23.4744 28.4094 23.4 28.4094 23.3225V21.8373C28.4094 21.7598 28.4402 21.6854 28.495 21.6306C28.5499 21.5758 28.6242 21.545 28.7018 21.545C28.7793 21.545 28.8537 21.5758 28.9085 21.6306C28.9633 21.6854 28.9941 21.7598 28.9941 21.8373V23.3225C28.9941 23.4 28.9633 23.4744 28.9085 23.5292C28.8537 23.584 28.7793 23.6148 28.7018 23.6148Z"
        fill="white"
      />
      <path
        d="M28.7018 25.7911C28.6242 25.7911 28.5499 25.7603 28.495 25.7055C28.4402 25.6506 28.4094 25.5763 28.4094 25.4987V25.0649C28.4094 24.9874 28.4402 24.913 28.495 24.8582C28.5499 24.8033 28.6242 24.7725 28.7018 24.7725C28.7793 24.7725 28.8537 24.8033 28.9085 24.8582C28.9633 24.913 28.9941 24.9874 28.9941 25.0649V25.4987C28.9941 25.5763 28.9633 25.6506 28.9085 25.7055C28.8537 25.7603 28.7793 25.7911 28.7018 25.7911Z"
        fill="white"
      />
      <path
        d="M54.1396 16.0371H54.9582V23.1085H54.1396V16.0371Z"
        fill="white"
      />
      <path
        d="M54.1196 24.1668H54.9382V26.2857H54.1196V24.1668Z"
        fill="white"
      />
      <path
        d="M28.7123 18.6858C28.6347 18.6858 28.5604 18.655 28.5055 18.6002C28.4507 18.5454 28.4199 18.471 28.4199 18.3935V11.3221C28.4199 11.2446 28.4507 11.1702 28.5055 11.1154C28.5604 11.0606 28.6347 11.0298 28.7123 11.0298C28.7898 11.0298 28.8642 11.0606 28.919 11.1154C28.9738 11.1702 29.0046 11.2446 29.0046 11.3221V18.3935C29.0046 18.471 28.9738 18.5454 28.919 18.6002C28.8642 18.655 28.7898 18.6858 28.7123 18.6858Z"
        fill="white"
      />
      <path
        d="M28.691 21.0492C28.6135 21.0492 28.5391 21.0183 28.4843 20.9635C28.4295 20.9087 28.3987 20.8343 28.3987 20.7568V19.8072C28.3987 19.7297 28.4295 19.6554 28.4843 19.6005C28.5391 19.5457 28.6135 19.5149 28.691 19.5149C28.7686 19.5149 28.8429 19.5457 28.8978 19.6005C28.9526 19.6554 28.9834 19.7297 28.9834 19.8072V20.7568C28.9834 20.8343 28.9526 20.9087 28.8978 20.9635C28.8429 21.0183 28.7686 21.0492 28.691 21.0492Z"
        fill="white"
      />
      <path
        d="M42.7287 37.5693H14.7555C14.1888 37.5693 13.6452 37.3442 13.2444 36.9436C12.8435 36.5429 12.6182 35.9995 12.6178 35.4328L12.5383 2.86619C12.5382 2.58552 12.5933 2.30758 12.7006 2.04823C12.8079 1.78889 12.9653 1.55322 13.1637 1.35471C13.3621 1.15619 13.5977 0.998715 13.857 0.891273C14.1162 0.783831 14.3942 0.72853 14.6748 0.72853H42.648C43.2143 0.727909 43.7578 0.952049 44.159 1.35174C44.5602 1.75143 44.7864 2.29401 44.788 2.86034L44.8652 35.4328C44.8652 35.9994 44.6401 36.5428 44.2394 36.9435C43.8387 37.3442 43.2953 37.5693 42.7287 37.5693Z"
        fill="white"
      />
      <path
        d="M44.989 37.2652H12.7417L12.6516 35.807H44.8978L44.989 37.2652Z"
        fill="#E1E4FE"
      />
      <path
        d="M44.7318 38.0955C44.5924 38.0955 44.4588 38.0402 44.3601 37.9418C44.2614 37.8433 44.2059 37.7098 44.2056 37.5704L44.119 2.37856C44.1181 2.0804 43.9991 1.79474 43.788 1.58412C43.577 1.3735 43.2911 1.25508 42.9929 1.25477H14.0514C13.9033 1.25477 13.7565 1.28401 13.6197 1.34083C13.4828 1.39764 13.3585 1.4809 13.2539 1.58585C13.1493 1.69079 13.0664 1.81535 13.01 1.95238C12.9536 2.08941 12.9249 2.23623 12.9253 2.38441L13.0119 37.5681C13.0119 37.7076 12.9566 37.8414 12.8582 37.9402C12.7598 38.039 12.6263 38.0949 12.4868 38.0955C12.3474 38.0955 12.2138 38.0402 12.1151 37.9418C12.0165 37.8433 11.9609 37.7098 11.9606 37.5704L11.874 2.38675C11.8734 2.1003 11.9293 1.81654 12.0384 1.55169C12.1475 1.28685 12.3078 1.0461 12.51 0.843229C12.7122 0.640355 12.9524 0.479328 13.2169 0.369355C13.4814 0.259382 13.765 0.202619 14.0514 0.202312H42.9929C43.5696 0.203236 44.1225 0.43242 44.5307 0.839774C44.9389 1.24713 45.1693 1.79952 45.1715 2.37622L45.2557 37.5681C45.256 37.7075 45.201 37.8413 45.1028 37.9401C45.0046 38.039 44.8711 38.0949 44.7318 38.0955Z"
        fill="#25252D"
      />
      <path
        d="M53.1001 38.2534H4.30347V8.24894H12.2986C12.4382 8.24894 12.5721 8.30438 12.6707 8.40307C12.7694 8.50176 12.8249 8.63561 12.8249 8.77517C12.8249 8.91474 12.7694 9.04859 12.6707 9.14727C12.5721 9.24596 12.4382 9.3014 12.2986 9.3014H5.35593V37.2009H52.0477V9.3014H44.7974C44.6578 9.3014 44.524 9.24596 44.4253 9.14727C44.3266 9.04859 44.2712 8.91474 44.2712 8.77517C44.2712 8.63561 44.3266 8.50176 44.4253 8.40307C44.524 8.30438 44.6578 8.24894 44.7974 8.24894H53.1001V38.2534Z"
        fill="#25252D"
      />
      <path
        d="M28.8198 42.1404C29.4107 42.1404 29.8898 41.6614 29.8898 41.0704C29.8898 40.4795 29.4107 40.0004 28.8198 40.0004C28.2288 40.0004 27.7498 40.4795 27.7498 41.0704C27.7498 41.6614 28.2288 42.1404 28.8198 42.1404Z"
        fill="white"
      />
      <path
        d="M28.8197 42.4913C28.5387 42.4913 28.264 42.4079 28.0304 42.2518C27.7967 42.0957 27.6146 41.8738 27.5071 41.6142C27.3995 41.3545 27.3714 41.0689 27.4262 40.7933C27.481 40.5176 27.6164 40.2645 27.8151 40.0658C28.0138 39.8671 28.2669 39.7318 28.5426 39.6769C28.8182 39.6221 29.1038 39.6502 29.3635 39.7578C29.6231 39.8653 29.845 40.0474 30.0011 40.2811C30.1572 40.5147 30.2406 40.7894 30.2406 41.0704C30.2403 41.4472 30.0905 41.8084 29.8241 42.0748C29.5577 42.3412 29.1965 42.491 28.8197 42.4913ZM28.8197 40.3524C28.6553 40.3524 28.4958 40.4088 28.3678 40.5121C28.2399 40.6154 28.1512 40.7595 28.1166 40.9203C28.082 41.0811 28.1035 41.2489 28.1777 41.3957C28.2518 41.5425 28.374 41.6595 28.5239 41.7272C28.6738 41.7948 28.8424 41.809 29.0015 41.7674C29.1606 41.7258 29.3007 41.631 29.3984 41.4986C29.496 41.3663 29.5454 41.2045 29.5382 41.0402C29.531 40.8759 29.4677 40.719 29.3588 40.5957C29.2914 40.5192 29.2084 40.458 29.1155 40.4161C29.0225 40.3741 28.9217 40.3524 28.8197 40.3524Z"
        fill="#25252D"
      />
      <path
        d="M39.2485 8.08171V17.0533H31.4089V8.08171H39.2485Z"
        fill="#CDCDCF"
      />
      <path
        d="M37.3506 9.77032H38.1692V12.6798H37.3506V9.77032Z"
        fill="white"
      />
      <path d="M37.3401 13.6094H38.1587V14.897H37.3401V13.6094Z" fill="white" />
      <path d="M28.1614 9.48732H28.98V12.3968H28.1614V9.48732Z" fill="white" />
      <path d="M28.1509 13.3265H28.9695V14.614H28.1509V13.3265Z" fill="white" />
      <path
        d="M29.7751 19.3173V21.8853H16.8124V19.3173H29.7751Z"
        fill="#CDCDCF"
      />
      <path
        d="M39.2473 23.9984V26.5664H16.8112V23.9984H39.2473Z"
        fill="#CDCDCF"
      />
      <path
        d="M29.7751 8.23374V17.2054H16.8124V8.23374H29.7751Z"
        fill="#CDCDCF"
      />
      <path
        opacity="0.5"
        d="M46.2251 39.6017H52.7118V40.4203H46.2251V39.6017Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M43.6326 39.5818H44.9329V40.4004H43.6326V39.5818Z"
        fill="white"
      />
      <path d="M0 18.4204H1.45707V19.8774H0V18.4204Z" fill="white" />
      <path d="M0 21.331H1.45707V22.7881H0V21.331Z" fill="white" />
      <path d="M0 24.2463H1.45707V25.7034H0V24.2463Z" fill="white" />
      <path d="M0 27.1604H1.45707V28.6175H0V27.1604Z" fill="white" />
      <path
        opacity="0.5"
        d="M44.989 10.8216H46.9887V37.5693H44.989V10.8216Z"
        fill="#21201F"
      />
      <path d="M21.2058 0H22.6629V1.45707H21.2058V0Z" fill="white" />
      <path d="M24.1211 0H25.5782V1.45707H24.1211V0Z" fill="white" />
      <path d="M27.0354 0H28.4925V1.45707H27.0354V0Z" fill="white" />
      <path d="M29.9507 0H31.4078V1.45707H29.9507V0Z" fill="white" />
      <path
        d="M10.8462 40.5442H21.2059V41.5966H10.8462V40.5442Z"
        fill="#25252D"
      />
    </svg>
  );
};
const PassionSvg = () => {
  return (
    <svg
      width="52"
      height="48"
      viewBox="0 0 52 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.7627 23.1438H45.9215V31.8159H5.7627V23.1438Z"
        fill="#ECEBEB"
      />
      <path
        d="M6.07495 26.2625H45.4532V27.9365H6.07495V26.2625Z"
        fill="#D8D2D2"
      />
      <path
        d="M7.47674 1.00014H8.20744C8.59239 1.00014 8.96157 1.15306 9.23377 1.42525C9.50597 1.69745 9.65889 2.06663 9.65889 2.45158V9.27369H6.00537V2.45158C6.00535 2.25928 6.04355 2.0689 6.11773 1.89149C6.19192 1.71409 6.30061 1.55319 6.43751 1.41814C6.57441 1.2831 6.73677 1.17661 6.91518 1.10485C7.09358 1.03309 7.28446 0.997497 7.47674 1.00014Z"
        fill="#ECEBEB"
      />
      <path
        d="M49.3393 9.27036H2.97954C2.06236 9.27036 1.31885 10.0139 1.31885 10.931V24.5919C1.31885 25.509 2.06236 26.2526 2.97954 26.2526H49.3393C50.2565 26.2526 51 25.509 51 24.5919V10.931C51 10.0139 50.2565 9.27036 49.3393 9.27036Z"
        fill="#E79094"
      />
      <path
        d="M4.65676 26.2426H2.57425C1.7572 26.2426 1.0896 25.4787 1.0896 24.5354V10.9642C1.0896 10.0243 1.75387 9.26038 2.57425 9.26038H4.65676C3.82309 9.27034 3.15882 10.0343 3.15882 10.9642V24.5487C3.15882 25.4919 3.82309 26.2426 4.65676 26.2426Z"
        fill="#94A5AF"
      />
      <path
        d="M50.4289 10.3V24.3361C50.4289 24.7246 50.2746 25.0972 49.9999 25.3719C49.7252 25.6465 49.3526 25.8009 48.9642 25.8009H24.5952C35.197 22.6356 39.4451 13.5118 40.9663 8.81866H48.9642C49.1579 8.81865 49.3497 8.85708 49.5285 8.93173C49.7073 9.00637 49.8696 9.11575 50.0058 9.25353C50.142 9.3913 50.2496 9.55474 50.3222 9.73437C50.3948 9.914 50.4311 10.1063 50.4289 10.3Z"
        fill="#B76669"
      />
      <path
        d="M7.95833 18.7263C9.01858 18.7263 9.87809 17.8668 9.87809 16.8065C9.87809 15.7463 9.01858 14.8868 7.95833 14.8868C6.89808 14.8868 6.03857 15.7463 6.03857 16.8065C6.03857 17.8668 6.89808 18.7263 7.95833 18.7263Z"
        fill="#E21F27"
      />
      <path
        d="M19.5762 18.7263C20.6365 18.7263 21.496 17.8668 21.496 16.8065C21.496 15.7463 20.6365 14.8868 19.5762 14.8868C18.516 14.8868 17.6565 15.7463 17.6565 16.8065C17.6565 17.8668 18.516 18.7263 19.5762 18.7263Z"
        fill="#FF777D"
      />
      <path
        d="M13.7672 18.7263C14.8274 18.7263 15.6869 17.8668 15.6869 16.8065C15.6869 15.7463 14.8274 14.8868 13.7672 14.8868C12.7069 14.8868 11.8474 15.7463 11.8474 16.8065C11.8474 17.8668 12.7069 18.7263 13.7672 18.7263Z"
        fill="#E21F27"
      />
      <path
        d="M13.7672 18.7263C14.8274 18.7263 15.6869 17.8668 15.6869 16.8065C15.6869 15.7463 14.8274 14.8868 13.7672 14.8868C12.7069 14.8868 11.8474 15.7463 11.8474 16.8065C11.8474 17.8668 12.7069 18.7263 13.7672 18.7263Z"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
      />
      <path
        d="M49.0205 9.27368H2.66069C1.74352 9.27368 1 10.0172 1 10.9344V24.5952C1 25.5124 1.74352 26.2559 2.66069 26.2559H49.0205C49.9376 26.2559 50.6811 25.5124 50.6811 24.5952V10.9344C50.6811 10.0172 49.9376 9.27368 49.0205 9.27368Z"
        stroke="#3E3127"
        stroke-width="1.95961"
        stroke-miterlimit="10"
      />
      <path
        d="M5.7627 26.2625H45.9215V34.4896H5.7627V26.2625Z"
        stroke="#3E3127"
        stroke-width="1.56769"
        stroke-miterlimit="10"
      />
      <path
        d="M7.94148 18.7263C9.00174 18.7263 9.86124 17.8668 9.86124 16.8065C9.86124 15.7463 9.00174 14.8868 7.94148 14.8868C6.88123 14.8868 6.02173 15.7463 6.02173 16.8065C6.02173 17.8668 6.88123 18.7263 7.94148 18.7263Z"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
      />
      <path
        d="M49.3393 29.5274H2.97954C2.06236 29.5274 1.31885 30.2709 1.31885 31.1881V44.8489C1.31885 45.7661 2.06236 46.5096 2.97954 46.5096H49.3393C50.2565 46.5096 51 45.7661 51 44.8489V31.1881C51 30.2709 50.2565 29.5274 49.3393 29.5274Z"
        fill="#E79094"
      />
      <path
        d="M50.6813 30.9955V45.0449C50.6818 45.2374 50.6442 45.428 50.5707 45.6059C50.4973 45.7838 50.3894 45.9455 50.2533 46.0816C50.1172 46.2177 49.9555 46.3256 49.7776 46.399C49.5997 46.4725 49.4091 46.51 49.2166 46.5096H24.8477C35.4495 43.3477 39.6975 34.2205 41.2187 29.5307H49.2166C49.4091 29.5303 49.5997 29.5679 49.7776 29.6413C49.9555 29.7148 50.1172 29.8227 50.2533 29.9588C50.3894 30.0949 50.4973 30.2565 50.5707 30.4344C50.6442 30.6123 50.6818 30.803 50.6813 30.9955Z"
        fill="#B76669"
      />
      <path
        d="M7.15112 34.7918H12.1897"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M7.15112 38.0202H12.1897"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M7.15112 41.2485H12.1897"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M17.9321 34.7918H22.9707"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M17.9321 38.0202H22.9707"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M17.9321 41.2485H22.9707"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M28.7134 34.7918H33.7486"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M28.7134 38.0202H33.7486"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M28.7134 41.2485H33.7486"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M39.4912 34.7918H44.5297"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M39.4912 38.0202H44.5297"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M39.4912 41.2485H44.5297"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M9.42306 3.4679L6.03857 6.39735V5.29133L9.42306 2.36188V3.4679Z"
        fill="white"
      />
      <path
        d="M9.42306 5.39432L6.03857 8.32377V7.21775L9.42306 4.2883V5.39432Z"
        fill="white"
      />
      <path
        d="M7.84194 1.00014C8.32467 1.00014 8.78762 1.1919 9.12896 1.53324C9.4703 1.87458 9.66206 2.33753 9.66206 2.82025V9.27369H6.00854V2.82025C6.00854 2.58011 6.05605 2.34234 6.14835 2.12064C6.24065 1.89895 6.37591 1.69771 6.54633 1.52852C6.71676 1.35933 6.91897 1.22554 7.14134 1.13486C7.3637 1.04417 7.60181 0.998387 7.84194 1.00014Z"
        stroke="#3E3127"
        stroke-width="1.56769"
        stroke-miterlimit="10"
      />
      <path
        d="M19.5762 18.7263C20.6365 18.7263 21.496 17.8668 21.496 16.8065C21.496 15.7463 20.6365 14.8868 19.5762 14.8868C18.516 14.8868 17.6565 15.7463 17.6565 16.8065C17.6565 17.8668 18.516 18.7263 19.5762 18.7263Z"
        stroke="#3E3127"
        stroke-width="1.17577"
        stroke-miterlimit="10"
      />
      <path
        d="M34.9679 20.4667C36.1712 20.4667 37.1467 19.4913 37.1467 18.2879C37.1467 17.0846 36.1712 16.1091 34.9679 16.1091C33.7646 16.1091 32.7891 17.0846 32.7891 18.2879C32.7891 19.4913 33.7646 20.4667 34.9679 20.4667Z"
        fill="#B76669"
      />
      <path
        d="M31.7825 16.0426C32.3291 16.0426 32.7723 15.5995 32.7723 15.0528C32.7723 14.5062 32.3291 14.0631 31.7825 14.0631C31.2359 14.0631 30.7927 14.5062 30.7927 15.0528C30.7927 15.5995 31.2359 16.0426 31.7825 16.0426Z"
        fill="#B76669"
      />
      <path
        d="M4.65676 46.503H2.57425C1.7572 46.503 1.0896 45.7391 1.0896 44.7991V31.2247C1.0896 30.2847 1.75387 29.5208 2.57425 29.5208H4.65676C3.82309 29.5275 3.15882 30.2914 3.15882 31.2247V44.8091C3.15882 45.7491 3.82309 46.503 4.65676 46.503Z"
        fill="#94A5AF"
      />
      <path
        d="M49.0205 29.5274H2.66069C1.74352 29.5274 1 30.2709 1 31.1881V44.8489C1 45.7661 1.74352 46.5096 2.66069 46.5096H49.0205C49.9376 46.5096 50.6811 45.7661 50.6811 44.8489V31.1881C50.6811 30.2709 49.9376 29.5274 49.0205 29.5274Z"
        stroke="#3E3127"
        stroke-width="1.95961"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

const AccountabilitySvg = () => {
  return (
    <svg
      width="47"
      height="57"
      viewBox="0 0 47 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.27164 30.9782C9.15767 30.9782 9.04634 30.9439 8.95213 30.8797C8.85792 30.8156 8.78519 30.7246 8.7434 30.6186C8.70161 30.5125 8.6927 30.3964 8.71783 30.2852C8.74295 30.174 8.80095 30.073 8.88427 29.9953L20.4561 19.1805C20.5104 19.1284 20.5744 19.0876 20.6445 19.0605C20.7146 19.0334 20.7894 19.0205 20.8645 19.0225C20.9396 19.0245 21.0136 19.0414 21.0822 19.0723C21.1507 19.1031 21.2125 19.1472 21.2638 19.2021C21.3152 19.257 21.3552 19.3215 21.3814 19.3919C21.4076 19.4623 21.4196 19.5373 21.4167 19.6124C21.4137 19.6875 21.3959 19.7613 21.3642 19.8294C21.3325 19.8976 21.2877 19.9588 21.2321 20.0095L9.65901 30.8242C9.55407 30.9228 9.41561 30.9778 9.27164 30.9782Z"
        fill="#25252D"
      />
      <path
        d="M25.9474 15.9541C25.8335 15.9541 25.7221 15.9198 25.6279 15.8556C25.5337 15.7915 25.461 15.7005 25.4192 15.5944C25.3774 15.4884 25.3685 15.3723 25.3936 15.2611C25.4187 15.1499 25.4767 15.0489 25.5601 14.9711L37.1332 4.16517C37.1869 4.11068 37.2511 4.06761 37.3219 4.03855C37.3927 4.00948 37.4686 3.99503 37.5451 3.99605C37.6216 3.99708 37.6971 4.01355 37.7671 4.0445C37.8371 4.07544 37.9001 4.12021 37.9524 4.17611C38.0046 4.23202 38.045 4.29791 38.0712 4.36983C38.0973 4.44174 38.1086 4.5182 38.1045 4.59461C38.1003 4.67102 38.0808 4.7458 38.047 4.81446C38.0132 4.88312 37.9659 4.94424 37.9079 4.99416L26.3348 15.8064C26.2291 15.903 26.0906 15.9558 25.9474 15.9541Z"
        fill="#25252D"
      />
      <path
        d="M42.7909 4.57526H36.3658C34.6745 4.57526 33.3035 5.94632 33.3035 7.63762V47.0446C33.3035 48.7359 34.6745 50.1069 36.3658 50.1069H42.7909C44.4822 50.1069 45.8532 48.7359 45.8532 47.0446V7.63762C45.8532 5.94632 44.4822 4.57526 42.7909 4.57526Z"
        fill="#E21F27"
      />
      <path
        d="M10.0553 32.6892H3.63023C1.93894 32.6892 0.567871 34.0602 0.567871 35.7515V46.6383C0.567871 48.3296 1.93894 49.7006 3.63023 49.7006H10.0553C11.7466 49.7006 13.1176 48.3296 13.1176 46.6383V35.7515C13.1176 34.0602 11.7466 32.6892 10.0553 32.6892Z"
        fill="#E21F27"
      />
      <path
        d="M13.1757 35.4096V46.8136C13.1757 48.3631 11.1884 49.6199 8.74049 49.6199H3.25928V32.6046H8.74049C11.1884 32.6046 13.1757 33.8601 13.1757 35.4096Z"
        fill="#373635"
      />
      <path
        d="M29.5434 35.4096V46.8136C29.5431 47.558 29.2472 48.2718 28.7207 48.798C28.1942 49.3243 27.4803 49.6199 26.736 49.6199H23.2686V32.6046H26.736C27.1045 32.6045 27.4694 32.6769 27.8099 32.8178C28.1505 32.9586 28.4599 33.1652 28.7206 33.4257C28.9813 33.6861 29.1882 33.9954 29.3293 34.3358C29.4705 34.6762 29.5433 35.0411 29.5434 35.4096Z"
        fill="#373635"
      />
      <path
        opacity="0.5"
        d="M13.1227 47.0092V47.2275C13.1227 47.9714 12.8272 48.6849 12.3011 49.2109C11.7751 49.7369 11.0616 50.0325 10.3177 50.0325H3.37788C3.00915 50.033 2.64393 49.9608 2.30311 49.8201C1.96229 49.6793 1.65254 49.4728 1.39158 49.2123C1.13061 48.9518 0.923542 48.6425 0.782206 48.3019C0.640869 47.9613 0.568037 47.5962 0.567871 47.2275V47.0092C0.567871 48.5587 1.82966 48.3126 3.37409 48.3126H10.3139C11.8609 48.3126 13.1227 48.5587 13.1227 47.0092Z"
        fill="#21201F"
      />
      <path
        d="M10.3353 50.2684H3.35005C2.46187 50.2674 1.61035 49.9141 0.982313 49.2861C0.354274 48.6581 0.0010019 47.8066 0 46.9184L0 35.4714C0.0010019 34.5832 0.354274 33.7317 0.982313 33.1037C1.61035 32.4756 2.46187 32.1224 3.35005 32.1214H10.3366C11.2245 32.1227 12.0757 32.4761 12.7035 33.1041C13.3313 33.7321 13.6844 34.5835 13.6854 35.4714V46.9184C13.6844 47.8066 13.3311 48.6581 12.703 49.2861C12.075 49.9141 11.2235 50.2674 10.3353 50.2684ZM3.35005 33.257C2.76295 33.2576 2.20009 33.4912 1.78494 33.9063C1.3698 34.3215 1.13628 34.8843 1.13561 35.4714V46.9184C1.13628 47.5055 1.3698 48.0683 1.78494 48.4835C2.20009 48.8986 2.76295 49.1321 3.35005 49.1328H10.3353C10.9224 49.1321 11.4853 48.8986 11.9004 48.4835C12.3156 48.0683 12.5491 47.5055 12.5498 46.9184V35.4714C12.5494 34.8844 12.3162 34.3216 11.9012 33.9064C11.4863 33.4912 10.9236 33.2576 10.3366 33.257H3.35005Z"
        fill="#25252D"
      />
      <path
        d="M26.4232 17.8139H19.9982C18.3069 17.8139 16.9358 19.185 16.9358 20.8763V47.0445C16.9358 48.7358 18.3069 50.1069 19.9982 50.1069H26.4232C28.1145 50.1069 29.4855 48.7358 29.4855 47.0445V20.8763C29.4855 19.185 28.1145 17.8139 26.4232 17.8139Z"
        fill="#373635"
      />
      <path
        d="M26.7045 50.6735H19.718C18.8289 50.6725 17.9766 50.3185 17.3485 49.6894C16.7203 49.0602 16.3676 48.2074 16.3679 47.3184V20.5962C16.3689 19.708 16.7222 18.8565 17.3502 18.2285C17.9783 17.6004 18.8298 17.2472 19.718 17.2462H26.7045C27.5927 17.2472 28.4442 17.6004 29.0722 18.2285C29.7003 18.8565 30.0535 19.708 30.0545 20.5962V47.3184C30.0549 48.2074 29.7022 49.0602 29.074 49.6894C28.4458 50.3185 27.5936 50.6725 26.7045 50.6735ZM19.718 18.3818C19.1309 18.3824 18.568 18.616 18.1529 19.0311C17.7377 19.4462 17.5042 20.0091 17.5035 20.5962V47.3184C17.5042 47.9055 17.7377 48.4683 18.1529 48.8835C18.568 49.2986 19.1309 49.5321 19.718 49.5328H26.7045C27.2916 49.5321 27.8545 49.2986 28.2696 48.8835C28.6847 48.4683 28.9183 47.9055 28.9189 47.3184V20.5962C28.9183 20.0091 28.6847 19.4462 28.2696 19.0311C27.8545 18.616 27.2916 18.3824 26.7045 18.3818H19.718Z"
        fill="#25252D"
      />
      <path
        d="M6.8428 35.6796C8.60727 35.6796 10.0376 34.2492 10.0376 32.4848C10.0376 30.7203 8.60727 29.2899 6.8428 29.2899C5.07833 29.2899 3.64795 30.7203 3.64795 32.4848C3.64795 34.2492 5.07833 35.6796 6.8428 35.6796Z"
        fill="white"
      />
      <path
        d="M10.0376 32.3737C10.0374 32.8267 9.94084 33.2744 9.75438 33.6872C9.56791 34.1 9.2958 34.4684 8.95609 34.768C8.61639 35.0677 8.21686 35.2916 7.78401 35.4251C7.35115 35.5585 6.89487 35.5984 6.44543 35.542C5.99599 35.4857 5.56367 35.3344 5.17715 35.0983C4.79062 34.8621 4.45873 34.5465 4.20349 34.1723C3.94825 33.7981 3.77549 33.3739 3.69668 32.9279C3.61787 32.4818 3.63481 32.0241 3.74637 31.5851C3.92133 32.2736 4.32077 32.8842 4.88154 33.3202C5.44231 33.7563 6.13242 33.9931 6.8428 33.9931C7.55318 33.9931 8.24329 33.7563 8.80406 33.3202C9.36483 32.8842 9.76427 32.2736 9.93923 31.5851C10.0054 31.8427 10.0385 32.1077 10.0376 32.3737Z"
        fill="#E1E4FE"
      />
      <path
        d="M6.84274 36.2474C6.09855 36.2474 5.37108 36.0267 4.75232 35.6133C4.13355 35.1999 3.65128 34.6122 3.36649 33.9247C3.08171 33.2371 3.0072 32.4806 3.15238 31.7507C3.29756 31.0208 3.65592 30.3504 4.18214 29.8242C4.70835 29.2979 5.37879 28.9396 6.10868 28.7944C6.83856 28.6492 7.59511 28.7237 8.28264 29.0085C8.97018 29.2933 9.55782 29.7756 9.97127 30.3943C10.3847 31.0131 10.6054 31.7406 10.6054 32.4848C10.6041 33.4823 10.2072 34.4385 9.50186 35.1439C8.79651 35.8492 7.84024 36.2461 6.84274 36.2474ZM6.84274 29.8577C6.32315 29.8577 5.81524 30.0118 5.38323 30.3005C4.95121 30.5891 4.6145 30.9994 4.41566 31.4794C4.21683 31.9595 4.1648 32.4877 4.26617 32.9973C4.36753 33.5069 4.61774 33.975 4.98513 34.3424C5.35253 34.7098 5.82063 34.96 6.33022 35.0613C6.83982 35.1627 7.36803 35.1107 7.84806 34.9118C8.32809 34.713 8.73838 34.3763 9.02704 33.9443C9.31571 33.5123 9.46978 33.0043 9.46978 32.4848C9.46911 31.7882 9.19212 31.1204 8.6996 30.6279C8.20708 30.1354 7.53927 29.8584 6.84274 29.8577Z"
        fill="#25252D"
      />
      <path
        d="M23.2107 20.6479C24.9752 20.6479 26.4056 19.2175 26.4056 17.4531C26.4056 15.6886 24.9752 14.2582 23.2107 14.2582C21.4463 14.2582 20.0159 15.6886 20.0159 17.4531C20.0159 19.2175 21.4463 20.6479 23.2107 20.6479Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M29.4868 47.355V47.5707C29.4865 48.3148 29.1907 49.0282 28.6644 49.5542C28.1382 50.0802 27.4246 50.3757 26.6806 50.3757H19.7407C18.9969 50.3753 18.2837 50.0797 17.7577 49.5538C17.2318 49.0278 16.9361 48.3145 16.9358 47.5707V47.3512C16.9358 48.9019 18.1976 48.6546 19.7433 48.6546H26.6831C28.2301 48.6584 29.4868 48.9057 29.4868 47.355Z"
        fill="#21201F"
      />
      <path
        d="M45.8545 40.9577C44.0615 42.3559 41.8528 43.1152 39.579 43.1152C37.3052 43.1152 35.0966 42.3559 33.3035 40.9577C32.6146 42.6308 33.3035 46.5789 33.3035 42.9109V43.4283C33.3035 47.095 34.0606 50.0678 35.6063 50.0678H43.5555C45.105 50.0678 45.857 47.095 45.857 43.4283L45.8545 40.9577Z"
        fill="#97282C"
      />
      <path
        d="M26.3892 17.2386C26.389 17.6915 26.2924 18.1393 26.1059 18.552C25.9195 18.9648 25.6474 19.3333 25.3077 19.6329C24.968 19.9325 24.5684 20.1565 24.1356 20.2899C23.7027 20.4233 23.2464 20.4632 22.797 20.4069C22.3476 20.3505 21.9152 20.1993 21.5287 19.9631C21.1422 19.727 20.8103 19.4113 20.5551 19.0371C20.2998 18.6629 20.1271 18.2388 20.0482 17.7927C19.9694 17.3467 19.9864 16.889 20.0979 16.45C20.2729 17.1384 20.6723 17.749 21.2331 18.1851C21.7939 18.6212 22.484 18.8579 23.1944 18.8579C23.9047 18.8579 24.5949 18.6212 25.1556 18.1851C25.7164 17.749 26.1158 17.1384 26.2908 16.45C26.3564 16.7077 26.3895 16.9726 26.3892 17.2386Z"
        fill="#E1E4FE"
      />
      <path
        d="M23.2168 21.2157C22.4726 21.2157 21.7451 20.995 21.1263 20.5816C20.5076 20.1681 20.0253 19.5805 19.7405 18.893C19.4557 18.2054 19.3812 17.4489 19.5264 16.719C19.6716 15.9891 20.0299 15.3187 20.5562 14.7925C21.0824 14.2662 21.7528 13.9079 22.4827 13.7627C23.2126 13.6175 23.9691 13.692 24.6567 13.9768C25.3442 14.2616 25.9318 14.7439 26.3453 15.3626C26.7587 15.9814 26.9794 16.7089 26.9794 17.4531C26.9781 18.4506 26.5812 19.4068 25.8759 20.1122C25.1705 20.8175 24.2143 21.2144 23.2168 21.2157ZM23.2168 14.826C22.6972 14.826 22.1893 14.9801 21.7573 15.2687C21.3252 15.5574 20.9885 15.9677 20.7897 16.4477C20.5909 16.9278 20.5388 17.456 20.6402 17.9656C20.7416 18.4752 20.9918 18.9433 21.3592 19.3107C21.7266 19.6781 22.1947 19.9283 22.7042 20.0296C23.2138 20.131 23.7421 20.079 24.2221 19.8801C24.7021 19.6813 25.1124 19.3446 25.4011 18.9126C25.6897 18.4805 25.8438 17.9726 25.8438 17.4531C25.8431 16.7565 25.5661 16.0887 25.0736 15.5962C24.5811 15.1037 23.9133 14.8267 23.2168 14.826Z"
        fill="#25252D"
      />
      <path
        d="M17.3054 55.2298H29.0173V56.3654H17.3054V55.2298Z"
        fill="#25252D"
      />
      <path
        d="M14.3455 55.2298H15.7334V56.3654H14.3455V55.2298Z"
        fill="#25252D"
      />
      <path
        d="M30.5908 55.2298H32.192V56.3654H30.5908V55.2298Z"
        fill="#25252D"
      />
      <path d="M43.1558 8.72906H44.039V16.3591H43.1558V8.72906Z" fill="white" />
      <path d="M43.1343 17.501H44.0175V19.7874H43.1343V17.501Z" fill="white" />
      <path
        d="M43.0724 50.6735H36.0872C35.1979 50.6728 34.3453 50.319 33.7168 49.6898C33.0884 49.0606 32.7355 48.2076 32.7358 47.3184V7.3575C32.7368 6.4691 33.0903 5.61739 33.7186 4.98931C34.3469 4.36124 35.1988 4.00811 36.0872 4.00745H43.0737C43.9616 4.00878 44.8128 4.3622 45.4406 4.9902C46.0684 5.61821 46.4215 6.46953 46.4225 7.3575V47.3184C46.4228 48.2074 46.0701 49.0602 45.4419 49.6894C44.8138 50.3185 43.9615 50.6725 43.0724 50.6735ZM36.0872 5.14306C35.4998 5.14339 34.9366 5.37676 34.5212 5.79194C34.1058 6.20712 33.8721 6.77018 33.8715 7.3575V47.3184C33.8711 47.6096 33.9282 47.8981 34.0394 48.1672C34.1505 48.4364 34.3137 48.6811 34.5194 48.8872C34.7252 49.0933 34.9696 49.2568 35.2386 49.3685C35.5076 49.4801 35.7959 49.5377 36.0872 49.5378H43.0737C43.6614 49.5368 44.2247 49.3025 44.6398 48.8863C45.0548 48.4701 45.2875 47.9061 45.2869 47.3184V7.3575C45.2862 6.7704 45.0527 6.20753 44.6375 5.79239C44.2224 5.37725 43.6595 5.14372 43.0724 5.14306H36.0872Z"
        fill="#25252D"
      />
      <path
        d="M39.8713 10.3214C41.796 10.3214 43.3564 8.76113 43.3564 6.83638C43.3564 4.91163 41.796 3.35132 39.8713 3.35132C37.9465 3.35132 36.3862 4.91163 36.3862 6.83638C36.3862 8.76113 37.9465 10.3214 39.8713 10.3214Z"
        fill="#97282C"
      />
      <path
        opacity="0.5"
        d="M26.543 25.9437H27.4262V32.9428H26.543V25.9437Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M26.5215 34.3371H27.4047V35.7402H26.5215V34.3371Z"
        fill="white"
      />
      <path
        d="M46.8591 23.0769V24.6491H45.2869V23.0769H46.8591Z"
        fill="white"
      />
      <path
        d="M46.8591 26.2225V27.7947H45.2869V26.2225H46.8591Z"
        fill="white"
      />
      <path
        d="M46.8591 29.3669V30.9391H45.2869V29.3669H46.8591Z"
        fill="white"
      />
      <path
        d="M46.8591 32.5125V34.0847H45.2869V32.5125H46.8591Z"
        fill="white"
      />
      <path
        d="M16.2385 24.8241L15.0938 25.9017L14.0161 24.7569L15.1609 23.6793L16.2385 24.8241Z"
        fill="white"
      />
      <path
        d="M13.9492 26.9808L12.8045 28.0585L11.7268 26.9137L12.8716 25.8361L13.9492 26.9808Z"
        fill="white"
      />
      <path
        d="M11.6594 29.1367L10.5147 30.2143L9.43703 29.0695L10.5818 27.9919L11.6594 29.1367Z"
        fill="white"
      />
      <path
        d="M39.9973 7.61365C41.922 7.61365 43.4823 6.05333 43.4823 4.12859C43.4823 2.20384 41.922 0.643524 39.9973 0.643524C38.0725 0.643524 36.5122 2.20384 36.5122 4.12859C36.5122 6.05333 38.0725 7.61365 39.9973 7.61365Z"
        fill="white"
      />
      <path
        d="M43.1532 3.95951C43.162 4.42284 43.0783 4.88327 42.9071 5.3139C42.7359 5.74452 42.4806 6.13669 42.156 6.46747C41.8315 6.79826 41.4442 7.06102 41.0169 7.24039C40.5896 7.41977 40.1309 7.51215 39.6675 7.51215C39.2041 7.51215 38.7453 7.41977 38.318 7.24039C37.8907 7.06102 37.5035 6.79826 37.1789 6.46747C36.8544 6.13669 36.599 5.74452 36.4278 5.3139C36.2566 4.88327 36.173 4.42284 36.1818 3.95951C36.1812 3.66929 36.2172 3.38016 36.289 3.09897C36.4803 3.84984 36.9163 4.5156 37.5281 4.99109C38.1399 5.46658 38.8926 5.72472 39.6675 5.72472C40.4423 5.72472 41.1951 5.46658 41.8069 4.99109C42.4187 4.5156 42.8546 3.84984 43.0459 3.09897C43.1177 3.38016 43.1537 3.66929 43.1532 3.95951Z"
        fill="#E1E4FE"
      />
      <path
        d="M39.7853 8.10826C38.9835 8.10826 38.1997 7.87049 37.533 7.42501C36.8663 6.97954 36.3467 6.34637 36.0398 5.60558C35.733 4.86478 35.6527 4.04963 35.8091 3.26321C35.9655 2.47679 36.3517 1.75441 36.9186 1.18743C37.4856 0.620449 38.208 0.23433 38.9944 0.0779008C39.7808 -0.0785287 40.596 0.0017566 41.3368 0.308604C42.0776 0.615451 42.7107 1.13508 43.1562 1.80178C43.6017 2.46848 43.8395 3.2523 43.8395 4.05413C43.8385 5.12904 43.411 6.15965 42.6509 6.91972C41.8908 7.6798 40.8602 8.10726 39.7853 8.10826ZM39.7853 1.13687C39.208 1.13687 38.6437 1.30807 38.1638 1.62882C37.6838 1.94957 37.3097 2.40545 37.0889 2.93882C36.868 3.47218 36.8103 4.05906 36.923 4.62523C37.0357 5.1914 37.3138 5.71143 37.7221 6.11954C38.1304 6.52765 38.6505 6.80552 39.2167 6.91799C39.7829 7.03046 40.3698 6.97248 40.9031 6.75139C41.4363 6.5303 41.8921 6.15603 42.2126 5.67592C42.5331 5.1958 42.7041 4.63141 42.7039 4.05413C42.7032 3.28041 42.3954 2.53859 41.8482 1.99161C41.301 1.44463 40.559 1.13721 39.7853 1.13687Z"
        fill="#25252D"
      />
    </svg>
  );
};
const Card = ({ title, details, icon }) => {
  return (
    <div className="bg-[#F0F1F199] p-[29px] rounded-md overflow-hidden max-w-[400px] mx-auto md:my-0 my-10">
      <div className="flex justify-between items-start">
        <h1 className="text-[#8C2529] text-[1.5rem] font-bold">{title}</h1>
        {icon}
      </div>
      <p className="text-[14px] text-[#000000] font-normal mt-[29px] leading-[26.66px]">
        {details}
      </p>
    </div>
  );
};
const WhatDrivesUs = () => {
  return (
    <div>
      <Fade bottom>
        <section className="md:py-[4.6rem] max-w-[1520px] mx-auto py-[2rem] lg:px-[64px] px-[1rem] relative overflow-hidden">
          <img
            src="/images/map.png"
            alt="map"
            className="absolute top-0 right-[-100px]"
          />
          <h1 className="lg:text-[3rem] font-bold text-[1.8rem]">
            What drives Us
          </h1>
          <div className="grid md:grid-cols-2 grid-cols-1 lg:w-[80%] w-full gap-[30px] lg:gap-[100px] mt-[50px]">
            <Card
              title="Customer Commitment"
              details="When our customers succeed, so do we. We are invested in every one of our customers and are consistently innovating to improve their standard of living and achieve long term financial success."
              icon={<CommitmentSvg />}
            />
            <Card
              title="Loyalty"
              details="We are dedicated to supporting our customers at every step of the way and keeping them on the path to a better financial future."
              icon={<LoyaltySvg />}
            />
            <Card
              title="Accountability"
              details="We are dedicated to supporting our customers at every step of the way and keeping them on the path to a better financial future."
              icon={<AccountabilitySvg />}
            />
            <Card
              title="Passion"
              details="Our positive, can-do approach to work drives our mission to design innovative solutions tailored to meet customers at their respective needs."
              icon={<PassionSvg />}
            />
          </div>
        </section>
      </Fade>
    </div>
  );
};

export default WhatDrivesUs;
