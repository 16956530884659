import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { Fade } from "react-reveal";

const GrowHero = () => {
  return (
    <Fade bottom>
      <div className="lg:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
        <div className="lg:flex max-w-[1520px] mx-auto flex-wrap gap-[20px]">
          <div className="flex-1">
            <h1 className="text-[60px] font-bold text-[#551C24]">
              Take Control of your future
            </h1>
            <p className="mt-[40px] mb-[25px] text-[18px] font-normal text-[#333333] leading-[29.67px]">
              Maximize your money’s potential with smart investment
              opportunities. With us, you can relax and watch your money do the
              work.
            </p>
            <div className="flex gap-[20px]">
              <Link to="#">
                <img
                  src="/images/g2.png"
                  alt="google button"
                  className="w-[120px] h-[36px] object-cover"
                />
              </Link>
              <Link to="#">
                <img
                  src="/images/iosBtn.png"
                  alt="ios button"
                  className="w-[120px] h-[36px] object-cover"
                />
              </Link>
                <div className="my-[20px]">
                  <img src="/images/qrc.png" alt="QRC code" />
                </div>
            </div>
            <div>
              <Button title="Sign Up Here" />
            </div>
          </div>
          <div
            className="flex-1 lg:relative mt-10 md:mt-0"
            style={{ backgroundImage: 'url("/images/flexgrow.png")' }}
          >
            <div className="lg:absolute hidden justify-around items-center lg:flex md:flex-row flex-col top-[70%] w-full">
              <img src="/images/3.png" alt="hero" className="mb-[20px] md:m-0" />
              <div>
                <img src="/images/1.png" alt="hero" className="mb-[10px]" />
                <img src="/images/2.png" alt="hero" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default GrowHero;
