import React from "react";
import Button from "../Button";
import Fade from "react-reveal/Fade";

const CasaHero = () => {
  return (
    <Fade bottom>
      <div className="md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
        <div className="lg:flex max-w-[1520px] mx-auto lg:gap-[10px]">
          <div className="flex-1">
            <h1 className="text-[#551C24] lg:text-[55px] text-[30px] leading-10 font-bold md:leading-[74px]">
              Experience a new standard of living.
            </h1>
            <p className="text-[#333333] lg:text-[18px] text-[16px] mt-[40px] font-normal">
              CASA is designed to make life easier and more convenient for the
              everyday Nigerian. Take off the burden of annual rent payments and
              start paying rent monthly. 
            </p>
            <div className="flex flex-wrap mt-[20px] gap-[20px] mb-10">
              <img
                src="/images/g2.png"
                alt="googleBtn"
                className="w-[126px] h-[35px] object-cover"
              />
              <img
                src="/images/i2.png"
                alt="IOSBtn"
                className="w-[126px] h-[35px] object-cover"
              />
                <img src="/images/qrc.png" alt="QRC code" />
            </div>
            <Button title="Sign Up Here" />
          </div>

          <div className="flex-1">
            <div className="max-w-[671px] mx-auto max-h-[585px] relative lg:mt-0 mt-10">
              <img
                src="/images/casaHero.png"
                alt="Capisave hero"
                className="w-full h-full object-cover"
              />
              <img
                src="/images/casa-card.png"
                alt="card"
                className="absolute bottom-0 lg:inline hidden left-0 right-0 mx-auto w-[313px] h-[151px] object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default CasaHero;
