import React from "react";
import ProductCard from "./ProductCard";
import Fade from "react-reveal/Fade";
export default function ProductSection() {
  return (
    <div className=" md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
      <Fade bottom>
        <section className=" max-w-[1520px] mx-auto">
          <div className="flex flex-col items-center justify-center max-w-[843px] mx-auto">
            <h1 className="text-center md:text-[2.75rem] md:font-extrabold text-[1.8rem] font-bold leading-8">
              Solutions tailored to your needs
            </h1>
            <p className="text-center text-[#333333] font-normal max-w-[681px] md:text-[1.25rem] leading-[29.17px] mt-4">
              Our products and services are designed to ease you into the life
              you’ve always wanted.
            </p>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[23px] my-[3rem]">
            <ProductCard
              image="/images/capiflex.png"
              title="Capiflex"
              details="Access loans to fund your needs – fees, medical expenses & so on."
              url="/product/capiflex"
              bg="rgba(36, 99, 98, 0.1) "
            />
            <ProductCard
              image="/images/capsav.png"
              title="CapiSave"
              details="Automate savings and reach your life goals easier and faster"
              url="/product/capisave"
              bg="rgba(176, 103, 53, 0.1)"
            />
            <ProductCard
              image="/images/capigrow.png"
              title="CapiGrow"
              details="Watch your money work for you and grow your wealth securely. "
              url="/product/capigrow"
              bg="rgba(56, 95, 177, 0.2)"
            />
            <ProductCard
              image="/images/asa.png"
              title="Casa"
              details="Easy monthly rent financing for salary earners and sme owners"
              url="/product/casa"
              bg="#E6E3D5"
            />
          </div>
        </section>
      </Fade>
    </div>
  );
}
