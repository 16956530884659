import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Fade from "react-reveal/Fade";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./testimoy.css";

// import required modules
import { Navigation } from "swiper";
const Rating = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0899 6.58947L9.99989 0L6.9099 6.58947L0 7.63697L5.00509 12.7593L3.82085 20L10 16.5773L16.1792 20L15.0058 12.7593L20 7.63697L13.0899 6.58947Z"
        fill="#8C2529"
      />
    </svg>
  );
};
const TestimonyCard = () => {
  return (
    <div
      className="bg-red-600 max-w-[30.8rem] mx-auto rounded-md"
      style={{ background: "rgba(62, 49, 38, 0.1)" }}
    >
      <div className="md:p-[58px] p-10">
        <p className="text-[#3D3333] text-[18px] font-normal  md:leading-[28px] leading-9">
          Capival has been such a great help to me and my family. I am grateful
          that I have a company like this on my side.”
        </p>
        <h1 className="font-bold text-[24px] my-2">David O.</h1>
        <div className="flex gap-1">
          <Rating />
          <Rating />
          <Rating />
          <Rating />
          <Rating />
        </div>
      </div>
    </div>
  );
};
const Testimonial = () => {
  return (
    <div className="md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem] my-[50px] relative md:mb-[100px]">
      <div className="hidden md:block absolute min-h-[441px] left-0 top-0 w-full bg-[#F9F9F9] h-[70%]"></div>
      <Fade bottom>
        <section className="max-w-[1520px] mx-auto">
          <div className="lg:flex justify-between gap-[60px] items-center ">
            <div className="flex-[40%] md:mt-[50px] z-30">
              <div className="max-w-[358px] max-h-[167px]">
                <img
                  src="/images/trusted.png"
                  alt="trusted"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="flex-[60%] overflow-hidden">
              <div className="md:max-w-[90%]">
                <Swiper
                  slidesPerView={1}
                  centeredSlides={true}
                  spaceBetween={20}
                  grabCursor={true}
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    630: {
                      slidesPerView: 1.6,
                    },
                  }}
                >
                  <SwiperSlide>
                    <TestimonyCard />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TestimonyCard />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TestimonyCard />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </Fade>
    </div>
  );
};

export default Testimonial;
