import React from 'react'
import { Link } from 'react-router-dom';

const Casa = () => {
  return (
    <>
      <div className="w-[8.6rem] bg-[#FCD890] font-normal text-[.9rem] px-2 text-[#1E1E1E]">
        <small>CASA</small>
      </div>
      <div className="md:flex gap-[4rem] items-center justify-center">
        <div className="flex-1 md:mt-[2.5rem] mt-8">
          <h1 className="md:text-[2.75rem] text-[2rem] text-[#1E1E1E] leading-9 font-extrabold md:leading-[52.56px]">
            Your Rent, We pay
          </h1>
          <p className="mt-4 text-[1.25rem] text-[#333333] font-normal leading-[29px]">
            Our products and services are designed to ease you into the life
            you’ve always wanted.
          </p>
          <div className="grid grid-cols-2 my-[23px] gap-[2rem]">
            <div className="flex gap-[.7rem] items-start">
              <div className="shrink">
                <svg
                  width="28"
                  height="17"
                  viewBox="0 0 28 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.654 16.535C9.68012 16.535 5.70622 16.535 1.73233 16.535C0.562167 16.535 0.00451799 15.9898 0.00387257 14.8362C0.000645427 10.497 0 6.15779 0 1.81857C0 0.714501 0.577012 0.146729 1.69489 0.146729C9.67496 0.146729 17.655 0.146729 25.6351 0.146729C26.7517 0.146729 27.3171 0.713858 27.3171 1.82756C27.3171 6.11347 27.3171 10.3994 27.3171 14.6846C27.3171 14.781 27.3171 14.8773 27.3158 14.973C27.299 15.9384 26.7097 16.5273 25.7371 16.5325C24.3836 16.5395 23.0308 16.5344 21.6773 16.5344C19.0033 16.5344 16.3287 16.5344 13.6547 16.5344L13.654 16.535ZM17.0541 13.6397C17.1781 13.6499 17.2168 13.6557 17.2561 13.6557C18.7587 13.6557 20.2619 13.6609 21.7645 13.6467C21.8748 13.6454 22.031 13.5292 22.0865 13.4251C22.5758 12.5099 23.269 11.804 24.2022 11.3435C24.3894 11.251 24.4307 11.1386 24.4301 10.9466C24.423 9.20539 24.423 7.46418 24.4301 5.72297C24.4307 5.53029 24.3849 5.41917 24.199 5.32733C23.2657 4.86617 22.5641 4.16288 22.1007 3.23479C22.013 3.05881 21.9019 3.02091 21.7238 3.02156C20.2851 3.02669 18.8471 3.02477 17.4085 3.02477C17.3181 3.02477 17.2271 3.02477 17.1367 3.02477C18.9356 4.36712 19.9482 6.10191 19.9489 8.33254C19.9489 10.5638 18.9536 12.3082 17.0541 13.639V13.6397ZM10.2533 3.02541C8.63001 3.02541 7.08486 3.02156 5.5397 3.0344C5.43773 3.03504 5.2938 3.14744 5.24152 3.24571C4.75551 4.16288 4.06297 4.87003 3.13032 5.33311C2.94767 5.42367 2.89345 5.52964 2.89474 5.72554C2.90249 7.46739 2.90184 9.20924 2.89474 10.9511C2.8941 11.1444 2.94121 11.2549 3.12645 11.3461C4.04877 11.8034 4.74583 12.4977 5.21054 13.4117C5.30993 13.6069 5.42675 13.6583 5.63329 13.6564C7.07195 13.6467 8.51061 13.6493 9.94991 13.6467C10.028 13.6467 10.1061 13.6326 10.2178 13.621C6.493 11.1431 6.35101 5.62663 10.2533 3.02413V3.02541ZM17.5873 8.36337C17.6105 6.25092 15.8614 4.46925 13.7341 4.4397C11.5661 4.40951 9.76145 6.14944 9.74079 8.29015C9.72014 10.4739 11.4751 12.2344 13.6921 12.2517C15.7846 12.2684 17.564 10.4925 17.5873 8.36401V8.36337Z"
                    fill="#E21F27"
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-[.9rem] font-extrabold">Up to N6Million</h3>
                <p className="text-[12px] text-[#333333]">
                  Tell us the amount and your rent is settled.
                </p>
              </div>
            </div>
            <div className="flex gap-[.7rem] items-start">
              <div className="shrink">
                <svg
                  width="26"
                  height="25"
                  viewBox="0 0 26 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_539_834)">
                    <path
                      d="M25.6793 5.80703C25.6793 7.38952 25.6743 8.97256 25.6832 10.5551C25.6848 10.9116 25.5741 11.1947 25.3211 11.4473C20.9204 15.8421 16.5237 20.2413 12.1258 24.6389C11.6447 25.1201 11.1547 25.1206 10.6764 24.6423C7.46265 21.4295 4.24949 18.2161 1.03689 15.0022C0.568017 14.5332 0.565236 14.0388 1.03022 13.5732C5.44031 9.16224 9.85208 4.75296 14.2611 0.339792C14.4913 0.10951 14.7511 0.000488281 15.0776 0.000488281C18.2796 0.00493816 21.4822 0.00271322 24.6842 0.00382569C25.3227 0.00382569 25.6781 0.361485 25.6787 1.00394C25.6804 2.60534 25.6793 4.20618 25.6793 5.80759V5.80703ZM16.7495 6.26425C16.7601 7.73772 17.9859 8.9492 19.4498 8.93252C20.9171 8.91583 22.1096 7.71492 22.1107 6.25257C22.1118 4.78189 20.8937 3.56596 19.4243 3.57096C17.9514 3.57597 16.7389 4.79746 16.7495 6.26425Z"
                      fill="#E21F27"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_539_834">
                      <rect
                        width="25"
                        height="25"
                        fill="white"
                        transform="translate(0.683105 0.000488281)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>
                <h3 className="text-[.9rem] font-extrabold">No Hidden Fees</h3>
                <p className="text-[12px] text-[#333333]">
                  We’re 100% transparent with you. 
                </p>
              </div>
            </div>
            <div className="flex gap-[.7rem] items-start">
              <div className="shrink">
                <svg
                  width="29"
                  height="26"
                  viewBox="0 0 29 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_539_848)">
                    <path
                      d="M9.23018 25.9998C7.5637 25.9998 5.8979 26.0012 4.23141 25.9998C2.00876 25.9971 0.342282 24.3138 0.325366 22.0395C0.313187 20.4387 0.31657 18.8378 0.32469 17.237C0.336192 14.9481 1.95734 13.2504 4.19285 13.2365C7.55896 13.2151 10.9258 13.2123 14.2919 13.2372C16.5206 13.2538 18.1512 14.9737 18.1587 17.2487C18.1634 18.8267 18.1641 20.4041 18.1587 21.9821C18.1506 24.2903 16.4793 25.9971 14.2296 25.9991C12.5631 26.0012 10.8973 25.9991 9.23086 25.9991L9.23018 25.9998Z"
                      fill="#E21F27"
                    />
                    <path
                      d="M14.8894 5.7707C14.8995 8.95512 12.3717 11.5519 9.25323 11.5602C6.13407 11.5679 3.58732 8.98765 3.57718 5.80738C3.5677 2.6195 6.09144 0.0136887 9.20113 0.000538471C12.3433 -0.0126117 14.8792 2.55998 14.8894 5.7707Z"
                      fill="#E21F27"
                    />
                    <path
                      d="M18.6999 13.2088C18.8406 13.1999 18.9171 13.1909 18.9935 13.1909C20.9848 13.1902 22.9767 13.1805 24.968 13.1936C26.9125 13.2068 28.2982 14.6339 28.3104 16.6203C28.3185 17.8979 28.3192 19.1756 28.3104 20.4532C28.2969 22.3884 26.9349 23.8287 25.0526 23.8612C23.4213 23.8896 21.79 23.8688 20.158 23.8681C20.0829 23.8681 20.0071 23.8508 19.9516 23.8439C20.3319 21.5516 20.112 19.2593 20.1384 16.9753C20.1546 15.5648 19.6181 14.3349 18.6992 13.2082L18.6999 13.2088Z"
                      fill="#E21F27"
                    />
                    <path
                      d="M20.9414 2.13356C23.5612 2.12664 25.6804 4.29573 25.6804 6.9839C25.6804 9.65824 23.5761 11.8301 20.9773 11.837C18.3317 11.8446 16.2065 9.68385 16.2031 6.98321C16.1997 4.29781 18.3074 2.14048 20.9414 2.13425V2.13356Z"
                      fill="#E21F27"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_539_848">
                      <rect
                        width="28"
                        height="26"
                        fill="white"
                        transform="translate(0.317139 0.000488281)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>
                <h3 className="text-[.9rem] font-extrabold">
                  Accessible to everyone
                </h3>
                <p className="text-[12px] text-[#333333]">
                  Salary earner or SME owner? We’ve got you.
                </p>
              </div>
            </div>
            <div className="flex gap-[.7rem] items-start">
              <div className="shrink">
                <svg
                  width="29"
                  height="40"
                  viewBox="0 0 29 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_539_752)">
                    <path
                      d="M14.9585 7.40345C22.0485 7.40835 27.8038 13.1106 27.787 20.1143C27.7696 27.1186 22.0236 32.7913 14.9499 32.7864C7.8618 32.7821 2.104 27.0762 2.12138 20.075C2.13875 13.0713 7.88538 7.39854 14.9585 7.40345ZM5.78453 20.086C5.77957 25.0759 9.88324 29.1496 14.93 29.1643C19.9879 29.1791 24.1189 25.1078 24.1245 20.1038C24.1301 15.1128 20.0276 11.0409 14.979 11.0262C9.92295 11.0114 5.78949 15.0839 5.78453 20.086Z"
                      fill="#E21F27"
                    />
                    <path
                      d="M13.1178 18.2869C13.1178 17.685 13.1035 17.0824 13.1209 16.4805C13.1495 15.4932 13.8183 14.7716 14.7844 14.6599C15.6325 14.5617 16.4745 15.125 16.709 15.9687C16.7686 16.1816 16.7866 16.4111 16.789 16.6339C16.7983 17.45 16.7816 18.2667 16.8021 19.0828C16.8064 19.2429 16.8951 19.4362 17.0093 19.5516C17.9691 20.5217 18.9532 21.4685 19.9112 22.4404C20.84 23.3835 20.5372 24.9138 19.3329 25.379C18.6088 25.6581 17.9139 25.5606 17.3512 25.0175C16.0966 23.8051 14.8638 22.5705 13.6272 21.3402C13.258 20.9727 13.1172 20.5112 13.1172 20.0019C13.1172 19.4307 13.1172 18.8588 13.1172 18.2875H13.1184L13.1178 18.2869Z"
                      fill="#E21F27"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_539_752">
                      <rect
                        width="25.6656"
                        height="25.3836"
                        fill="white"
                        transform="translate(2.12158 7.40344)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>
                <h3 className="text-[.9rem] font-extrabold">
                  Flexible Repayments
                </h3>
                <p className="text-[12px] text-[#333333]">
                  Monthly or quarterly? You choose.
                </p>
              </div>
            </div>
          </div>
          <div className="flex my-[2.5rem] md:gap-[2rem] gap-4">
            <Link
              to="/product/casa"
              className="bgGradient px-[1.8rem] py-[11px] text-white font-semibold text-.9rem]"
            >
              Apply Now
            </Link>
            <Link
              to="/product/casa"
              className="px-[1.8rem] py-[11px] text-[#1E1E1E] border-2 border-[#1E1E1E] rounded-2xl font-semibold text-[.9rem]"
            >
              Learn More
            </Link>
          </div>
        </div>
        <div className="flex-1">
          <div className="max-w-[28rem] h-[364px]">
            <img
              src="/images/casa.png"
              alt="casa"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Casa
