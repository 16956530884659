import React from "react";
import Fade from "react-reveal/Fade";

const BuildSection = () => {
  return (
    <div className=" md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
      <Fade bottom>
        <section className="max-w-[1512px] mx-auto lg:flex justify-center items-center mb-[30px] md:py-[2rem]">
          <div className="flex-1 flex justify-center">
            <h1 className="lg:max-w-[311px] text-[#551C24] lg:text-[3rem] text-[2rem] font-bold">
              Building better, together.
            </h1>
          </div>
          <div className="flex-1 flex justify-center">
            <p className="max-w-[580px] text-[#333333] font-medium text-[1.25rem] leading-[29.67px] my-[1rem]">
              With us, no dream is too small or too big. We are committed to
              your journey from start to finish. For every milestone, we’ll be
              right with you; providing data-led lending and wealth generating
              opportunities to empower you on your journey to financial
              independence.
            </p>
          </div>
        </section>
      </Fade>
    </div>
  );
};

export default BuildSection;
