import React from "react";

const WhyCustomersCard = ({icon, title, detail}) => {
  return (
    <div className="rounded-md customer-card max-w-[371px] mx-auto">
      <div className="shrink w-[25px]">
        <img src={icon} alt="process-simple" className="w-full object-cover" />
      </div>
      <div>
        <h2 className="text-[#FFB41E] font-extrabold text-[13px]">{ title}</h2>
        <p className="text-[.7rem] my-[6px] line-clamp-2">
        {detail}
        </p>
      </div>
    </div>
  );
};

export default WhyCustomersCard;
