import React, { useEffect } from 'react'

export default function ErrorPage() {
  const pathname = window.location.pathname;
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [pathname]);
  return (
    <div className='min-h-screen'>
      Error Page
    </div>
  )
}
