import React from "react";
import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import { Autoplay, Pagination } from "swiper";
const Investor = () => {
  return (
    <Fade bottom>
      <div
        style={{
          backgroundImage: "url('/images/invest.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="text-white flex justify-center mt-[50px] items-center md:px-[115px] px-[1rem] md:py-[10rem] py-[4rem] w-full"
      >
        <>
          <Swiper
            pagination={true}
            modules={[Pagination, Autoplay]}
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
            }}
            loop={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="max-w-[890px] mx-auto flex justify-center flex-col items-center">
                <h1 className="md:text-[2.25rem] text-[1.8rem] font-bold text-center">
                  What our Investors think about Capigrow
                </h1>
                <p className="my-[22px] md:text-[1.25rem] text-[1rem] font-normal text-center">
                  We’re a team of young , vibrant professionals dedicated to
                  making the world a better place, one financial solution at a
                  time. Our values are interwoven into everything we do and
                  we’re always ready to take on any challenge and get things
                  done! 
                </p>
                <p className="font-bold md:text-[1.25rem] tex-[1rem] text-center">
                  Adejayi Abimbola Rayosola
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="max-w-[890px] mx-auto flex justify-center flex-col items-center">
                <h1 className="md:text-[2.25rem] text-[1.8rem] font-bold text-center">
                  What our Investors think about Capigrow
                </h1>
                <p className="my-[22px] md:text-[1.25rem] text-[1rem] font-normal text-center">
                  We’re a team of young , vibrant professionals dedicated to
                  making the world a better place, one financial solution at a
                  time. Our values are interwoven into everything we do and
                  we’re always ready to take on any challenge and get things
                  done! 
                </p>
                <p className="font-bold md:text-[1.25rem] tex-[1rem] text-center">
                  Adejayi Abimbola Rayosola
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="max-w-[890px] mx-auto flex justify-center flex-col items-center">
                <h1 className="md:text-[2.25rem] text-[1.8rem] font-bold text-center">
                  What our Investors think about Capigrow
                </h1>
                <p className="my-[22px] md:text-[1.25rem] text-[1rem] font-normal text-center">
                  We’re a team of young , vibrant professionals dedicated to
                  making the world a better place, one financial solution at a
                  time. Our values are interwoven into everything we do and
                  we’re always ready to take on any challenge and get things
                  done! 
                </p>
                <p className="font-bold md:text-[1.25rem] tex-[1rem] text-center">
                  Adejayi Abimbola Rayosola
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="max-w-[890px] mx-auto flex justify-center flex-col items-center">
                <h1 className="md:text-[2.25rem] text-[1.8rem] font-bold text-center">
                  What our Investors think about Capigrow
                </h1>
                <p className="my-[22px] md:text-[1.25rem] text-[1rem] font-normal text-center">
                  We’re a team of young , vibrant professionals dedicated to
                  making the world a better place, one financial solution at a
                  time. Our values are interwoven into everything we do and
                  we’re always ready to take on any challenge and get things
                  done! 
                </p>
                <p className="font-bold md:text-[1.25rem] tex-[1rem] text-center">
                  Adejayi Abimbola Rayosola
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </>
      </div>
    </Fade>
  );
};

export default Investor;
