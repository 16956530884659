import React from "react";
import { Fade } from "react-reveal";

const Loan = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1352 13.546C7.99801 13.546 4.86078 13.546 1.72356 13.546C0.799765 13.546 0.359524 13.1156 0.359014 12.2049C0.356467 8.77926 0.355957 5.35363 0.355957 1.92801C0.355957 1.05639 0.811484 0.608154 1.694 0.608154C7.99393 0.608154 14.2939 0.608154 20.5938 0.608154C21.4753 0.608154 21.9216 1.05588 21.9216 1.9351C21.9216 5.31865 21.9216 8.70219 21.9216 12.0852C21.9216 12.1613 21.9216 12.2373 21.9206 12.3129C21.9074 13.075 21.4422 13.54 20.6743 13.544C19.6058 13.5496 18.5378 13.5455 17.4693 13.5455C15.3583 13.5455 13.2468 13.5455 11.1357 13.5455L11.1352 13.546ZM13.8195 11.2603C13.9173 11.2684 13.9479 11.2729 13.979 11.2729C15.1652 11.2729 16.3519 11.277 17.5381 11.2658C17.6252 11.2648 17.7485 11.173 17.7923 11.0909C18.1786 10.3684 18.7258 9.81111 19.4626 9.44756C19.6104 9.37454 19.643 9.28581 19.6425 9.1342C19.6369 7.75959 19.6369 6.38497 19.6425 5.01036C19.643 4.85825 19.6068 4.77053 19.4601 4.69802C18.7233 4.33396 18.1694 3.77874 17.8036 3.04605C17.7343 2.90712 17.6466 2.8772 17.506 2.87771C16.3702 2.88177 15.235 2.88024 14.0992 2.88024C14.0279 2.88024 13.956 2.88024 13.8847 2.88024C15.3048 3.93998 16.1042 5.30952 16.1048 7.07051C16.1048 8.832 15.319 10.2091 13.8195 11.2598V11.2603ZM8.45048 2.88075C7.16899 2.88075 5.94915 2.87771 4.72932 2.88785C4.64881 2.88836 4.53519 2.97709 4.49391 3.05467C4.11023 3.77874 3.5635 4.337 2.82722 4.70258C2.68302 4.77408 2.64022 4.85774 2.64123 5.01239C2.64735 6.38751 2.64684 7.76263 2.64123 9.13775C2.64072 9.29037 2.67792 9.37758 2.82416 9.44958C3.55229 9.8106 4.10259 10.3587 4.46946 11.0803C4.54793 11.2344 4.64015 11.275 4.8032 11.2734C5.93896 11.2658 7.07472 11.2679 8.21099 11.2658C8.27265 11.2658 8.3343 11.2547 8.42245 11.2456C5.48191 9.28936 5.36981 4.9343 8.45048 2.87974V2.88075ZM14.2404 7.09484C14.2587 5.42716 12.8778 4.0206 11.1984 3.99728C9.48688 3.97344 8.06221 5.34704 8.0459 7.03704C8.0296 8.76101 9.41503 10.1508 11.1653 10.1645C12.8172 10.1777 14.222 8.77571 14.2404 7.09535V7.09484Z"
        fill="#E21F27"
      />
    </svg>
  );
};

const Repayment = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6144 3.42588C11.6144 2.91245 11.6029 2.4395 11.6171 1.967C11.6364 1.34545 12.1422 0.767953 12.776 0.690538C13.0081 0.662063 13.2901 0.712784 13.4942 0.822678C14.6192 1.42999 15.7405 2.0462 16.8375 2.69933C18.0267 3.40719 19.1897 4.15599 20.3605 4.89277C21.1787 5.40798 21.2255 6.44374 20.4215 6.94249C18.4367 8.17313 16.4344 9.37752 14.4353 10.5859C14.1578 10.7536 13.8492 10.8724 13.5699 11.0375C12.6077 11.607 11.6254 10.6949 11.6153 9.9212C11.6102 9.52167 11.6116 9.12169 11.6098 8.72216C11.6098 8.64252 11.6098 8.56288 11.6098 8.47612C11.5437 8.46544 11.5006 8.45209 11.4571 8.45209C9.36626 8.45432 7.27407 8.41694 5.1851 8.47345C3.55657 8.51749 2.37198 9.33391 1.60518 10.7238C1.57308 10.7826 1.55611 10.8622 1.50612 10.8956C1.41944 10.9539 1.3002 11.0322 1.21673 11.0126C1.12134 10.9903 1.04797 10.8724 0.97092 10.7906C0.95441 10.7728 0.958536 10.7336 0.958995 10.7043C0.968168 9.56438 0.930103 8.42228 0.997978 7.28552C1.11676 5.29408 2.93882 3.57359 4.98973 3.44768C5.23325 3.43255 5.47769 3.42632 5.72167 3.42588C7.58363 3.4241 9.44559 3.42499 11.3076 3.42499C11.3979 3.42499 11.4878 3.42499 11.6134 3.42499L11.6144 3.42588Z"
        fill="#E21F27"
      />
      <path
        d="M10.3415 18.9389C10.3415 19.4328 10.3479 19.8973 10.3401 20.3617C10.3291 21.0055 9.8228 21.5973 9.17065 21.676C8.94502 21.7032 8.67077 21.6565 8.47265 21.5492C7.32841 20.9295 6.18693 20.3017 5.06975 19.6374C3.89342 18.9376 2.74184 18.1977 1.58522 17.4667C0.769356 16.951 0.734501 15.9162 1.54211 15.4165C3.52194 14.1912 5.51736 12.9899 7.51048 11.7851C7.79436 11.6134 8.10851 11.4892 8.3956 11.3224C9.36877 10.7569 10.3397 11.6957 10.3401 12.4565C10.3401 12.9294 10.3401 13.4019 10.3401 13.8869C10.3984 13.898 10.441 13.9136 10.4832 13.9136C12.5823 13.9114 14.6822 13.9492 16.7795 13.8913C18.3878 13.8469 19.5674 13.0447 20.3323 11.6721C20.3576 11.6267 20.3832 11.5809 20.4052 11.5342C20.4736 11.3909 20.5804 11.3095 20.7492 11.3424C20.9308 11.3776 20.9959 11.5061 20.995 11.6694C20.9872 12.8022 21.0221 13.9372 20.9574 15.0664C20.8506 16.9234 19.2606 18.5741 17.3614 18.8655C17.039 18.9149 16.7088 18.9354 16.3819 18.9362C14.4736 18.9425 12.5658 18.9394 10.6575 18.9394C10.5607 18.9394 10.4639 18.9394 10.3415 18.9394L10.3415 18.9389Z"
        fill="#E21F27"
      />
    </svg>
  );
};

const IntRate = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.12497 20.8242C2.32691 20.0285 1.639 19.3414 0.894043 18.598C5.83536 13.6567 10.7997 8.69319 15.7996 3.69323H12.032V0.70387C12.1572 0.69753 12.2951 0.68485 12.433 0.68485C14.7147 0.683265 16.9971 0.682473 19.2787 0.68485C20.3986 0.685643 20.9977 1.27686 20.9993 2.38637C21.0025 4.68149 21.0001 6.97661 21.0001 9.27172V9.65213H18.0194V5.93049C13.0314 10.9194 8.06945 15.8813 3.12576 20.8242H3.12497Z"
        fill="#E21F27"
      />
      <path
        d="M8.99741 5.19897C8.99266 7.68429 6.96938 9.69251 4.47851 9.68221C2.01142 9.6727 0.0071513 7.66289 1.86839e-05 5.19342C-0.00711393 2.71761 2.02885 0.680061 4.50466 0.683231C6.98919 0.687194 9.00217 2.70889 8.99741 5.19818V5.19897ZM5.99458 5.19897C5.99934 4.36762 5.32808 3.68448 4.50387 3.68131C3.68758 3.67893 2.99255 4.37634 2.9973 5.19263C3.00206 6.00337 3.66697 6.66987 4.47851 6.67859C5.32174 6.68731 5.98904 6.03586 5.99458 5.19818V5.19897Z"
        fill="#E21F27"
      />
      <path
        d="M16.4896 12.6851C18.9765 12.6835 21.0006 14.7036 20.9974 17.1849C20.9942 19.6623 18.9488 21.6999 16.4809 21.684C14.0066 21.6682 12.0024 19.6615 12 17.1968C11.9976 14.6996 14.0003 12.6874 16.4888 12.6851H16.4896ZM16.5157 15.6879C15.6725 15.68 15.0068 16.3314 15.0028 17.1699C14.9989 18.0012 15.6717 18.6852 16.4951 18.686C17.294 18.686 17.9779 18.0163 18.0001 17.2111C18.0223 16.3932 17.3431 15.6958 16.5157 15.6879Z"
        fill="#E21F27"
      />
    </svg>
  );
};
const Guarantor = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.53758 21.3392C6.18758 21.3392 4.83812 21.3404 3.48811 21.3392C1.68756 21.337 0.337553 19.9734 0.32385 18.131C0.313984 16.8342 0.316725 15.5373 0.323302 14.2405C0.33262 12.3863 1.6459 11.011 3.45687 10.9998C6.18374 10.9824 8.91116 10.9802 11.638 11.0003C13.4435 11.0138 14.7645 12.4071 14.7705 14.25C14.7743 15.5284 14.7749 16.8062 14.7705 18.0845C14.7639 19.9544 13.4101 21.337 11.5876 21.3387C10.2376 21.3404 8.88814 21.3387 7.53813 21.3387L7.53758 21.3392Z"
        fill="#E21F27"
      />
      <path
        d="M12.122 4.95176C12.1302 7.53144 10.0824 9.6351 7.55616 9.64183C5.02935 9.648 2.96625 7.55779 2.95803 4.98147C2.95036 2.39899 4.99482 0.288037 7.51396 0.277384C10.0594 0.266732 12.1137 2.35077 12.122 4.95176Z"
        fill="#E21F27"
      />
      <path
        d="M15.209 10.9774C15.3231 10.9701 15.385 10.9628 15.4469 10.9628C17.06 10.9622 18.6737 10.9544 20.2868 10.965C21.8621 10.9757 22.9846 12.1318 22.9945 13.7409C23.001 14.776 23.0016 15.811 22.9945 16.846C22.9835 18.4136 21.8801 19.5804 20.3553 19.6068C19.0338 19.6297 17.7123 19.6129 16.3902 19.6124C16.3294 19.6124 16.268 19.5983 16.2231 19.5927C16.5311 17.7358 16.353 15.8788 16.3743 14.0286C16.3875 12.8859 15.9528 11.8896 15.2085 10.9768L15.209 10.9774Z"
        fill="#E21F27"
      />
      <path
        d="M17.025 2.00538C19.1473 1.99978 20.864 3.75694 20.864 5.93462C20.864 8.10108 19.1593 9.86048 17.054 9.86609C14.9109 9.87226 13.1893 8.12182 13.1865 5.93406C13.1838 3.75862 14.8912 2.01099 17.025 2.00595V2.00538Z"
        fill="#E21F27"
      />
    </svg>
  );
};
const Disburse = () => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8372 0.683231C19.9271 0.68814 25.6824 6.39037 25.6657 13.3941C25.6483 20.3984 19.9023 26.0711 12.8285 26.0662C5.74047 26.0619 -0.0173335 20.356 3.921e-05 13.3548C0.0174119 6.3511 5.76404 0.678322 12.8372 0.683231ZM3.66319 13.3658C3.65823 18.3557 7.7619 22.4294 12.8087 22.4441C17.8666 22.4588 21.9976 18.3876 22.0032 13.3836C22.0087 8.39256 17.9063 4.32068 12.8577 4.30595C7.80161 4.29123 3.66816 8.36372 3.66319 13.3658Z"
        fill="#E21F27"
      />
      <path
        d="M10.9962 11.5668C10.9962 10.9648 10.982 10.3623 10.9993 9.7603C11.0279 8.77301 11.6967 8.05142 12.6628 7.93974C13.5109 7.84156 14.3529 8.40485 14.5874 9.24856C14.647 9.46148 14.665 9.69097 14.6675 9.91371C14.6768 10.7298 14.66 11.5465 14.6805 12.3626C14.6848 12.5228 14.7736 12.716 14.8877 12.8314C15.8476 13.8015 16.8316 14.7483 17.7896 15.7202C18.7184 16.6634 18.4156 18.1937 17.2113 18.6588C16.4872 18.938 15.7923 18.8404 15.2296 18.2974C13.975 17.0849 12.7422 15.8503 11.5056 14.6201C11.1365 14.2525 10.9956 13.7911 10.9956 13.2818C10.9956 12.7105 10.9956 12.1386 10.9956 11.5674H10.9969L10.9962 11.5668Z"
        fill="#E21F27"
      />
    </svg>
  );
};

const HiddenCa = () => {
  return (
    <svg
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6795 5.99886C24.6795 7.58135 24.6745 9.1644 24.6834 10.7469C24.6851 11.1034 24.5744 11.3866 24.3213 11.6391C19.9207 16.0339 15.5239 20.4332 11.1261 24.8308C10.6449 25.3119 10.1549 25.3125 9.67661 24.8341C6.4629 21.6213 3.24974 18.4079 0.0371363 15.194C-0.431739 14.7251 -0.43452 14.2306 0.0304619 13.765C4.44056 9.35407 8.85232 4.9448 13.2613 0.531625C13.4916 0.301344 13.7513 0.192322 14.0778 0.192322C17.2798 0.196772 20.4824 0.194547 23.6845 0.195659C24.323 0.195659 24.6784 0.553319 24.6789 1.19577C24.6806 2.79717 24.6795 4.39802 24.6795 5.99942V5.99886ZM15.7497 6.45609C15.7603 7.92956 16.9862 9.14104 18.4501 9.12435C19.9173 9.10766 21.1098 7.90675 21.1109 6.44441C21.112 4.97372 19.894 3.75779 18.4245 3.7628C16.9517 3.7678 15.7392 4.9893 15.7497 6.45609Z"
        fill="#E21F27"
      />
    </svg>
  );
};
export default function ContactDescription() {
  return (
    <Fade bottom>
      <div className="md:py-[4.6rem] py-[2rem] lg:px-[64px] px-[1rem]">
        <div className="gap-[25px] max-w-[1520px] mx-auto">
          <div className="lg:flex justify-center items-center gap-[25px]">
            <div className="lg:flex-1 flex justify-start items-center relative">
              <img
                src="/images/mockup.png"
                alt="Mock up"
                className="border-4 max-h-[578px] w-[267px] mx-auto border-[#3333334A] rounded-2xl object-cover"
              />
              <div className="absolute left-0 right-0 w-fit m-auto bottom-[104px]">
                <img src="/images/mockupCard.png" alt="mockupcard" />
              </div>
            </div>
            <div className="flex-1">
              <div className="md:my-0 my-10">
                <h1 className="text-[#1E1E1E] font-extrabold md:text-[2.38rem] text-[1.8rem]">
                  Lending made simple
                </h1>
                <p className="text-[#333333] font-normal text-justify md:text-[1rem] text-[1rem] my-[30px]">
                  Getting a loan shouldn’t be a hassle. Our loans are
                  salary-backed and designed to take the stress of adulting off
                  you.
                </p>
                <ul className="font-bold flex flex-col gap-5 text-[18px]">
                  <li className="flex items-center gap-8">
                    <span>
                      <Loan />
                    </span>
                    <span>Personal Loans up to 4 Million Naira</span>
                  </li>
                  <li className="flex items-center gap-8">
                    <span>
                      <Repayment />
                    </span>
                    <span>Easy Repayment & Tenure</span>
                  </li>
                  <li className="flex items-center gap-8">
                    <span>
                      <IntRate />
                    </span>
                    <span>Competitive Interest Rate</span>
                  </li>
                  <li className="flex items-center gap-8">
                    <span>
                      <Guarantor />
                    </span>
                    <span>No Collateral or Guarantor Required</span>
                  </li>
                  <li className="flex items-center gap-8">
                    <span>
                      <Disburse />
                    </span>
                    <span>Quick and Hassle Free Disbursement</span>
                  </li>
                  <li className="flex items-center gap-8">
                    <span>
                      <HiddenCa />
                    </span>{" "}
                    <span>No Hidden Charges</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
